/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react"
import { useContext, useEffect } from "react"
import NoImage from "src/components/templete/NoImage"
import { SiteContext } from "src/context/SiteContext"
import { OfferContext } from "src/context/OfferContext"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, Button, Form, Row, Table } from "react-bootstrap"
import { ClientContext } from "src/context/ClientContext"
import ProductLine from 'src/components/offer/ProductLine'
import { ProductContext } from "src/context/ProductContext"
import {formatMultiplyValue, formatValueToMoney} from "src/helpers/helpers"

function CreateOffer() {
  const navigate = useNavigate()
  const { productId } = useParams()
  const { client } = useContext(ClientContext)
  const { loading, create } = useContext(OfferContext)
  const { notifyMessageWarning } = useContext(SiteContext)
  const { getAllProductsByClient, getProduct, product, myProducts } = useContext(ProductContext)
  const [currentProduct, setCurrentProduct] = useState({
    image: "",
    name:"",
    value: "",
    quantity: 0
  })
  const [quantity, setQuantity] = useState(0)
  const [amountProductOffer, setAmountProductOffer] = useState(0)
  const [amountProductSend, setAmountProductSend] = useState(0)
  const [offerData, setOfferData] = useState({
    products: [],
    description: ''
  });

  const [blockOffer, setBlockOffer] = useState(false)

  useEffect(()=>{
    if(productId) {
      getProduct(productId)
    }
  },[productId])

  useEffect(()=>{
    if(client) {
      getAllProductsByClient('', client.id)
    }
  },[client])

  useEffect(()=>{
    if(product && product.data) {
      setCurrentProduct(product.data)
      setQuantity(1)
      setAmountProductOffer(currentProduct.value)

      if(product.data.quantity <= 0 ) {
        setBlockOffer(true);
      }
    }
  },[product])

  useEffect(() => {
    calculateProductOfferAmount()
  },[quantity])

  const onChangeHandler = event => {
    setOfferData({ ...offerData, [event.target.name]: event.target.value });
  };

  const changeQuantity = (type) => {
    let value = quantity
    if(type === 'mor') {
      value ++
      if(value > currentProduct.quantity) {
        value = currentProduct.quantity
        notifyMessageWarning(`Este producto só tem ${currentProduct.quantity} disponível`)
      }
    } else {
      value --
      if(value <= 0) {
        value = 1
        notifyMessageWarning(`O mínimo de produto é 1`)
      }
    }
    setQuantity(value)
  }

  const changeQuantityProductSend = async (product) => {
    let products = offerData.products
    products.forEach((productEach, index) => {
      if(productEach.id === product.id) {
        products.splice(index, 1);
      }
    })
    if(product.quantity > 0) {
      products.push(product)
    }

    products.filter((item, index) => products.indexOf(item) === index)

    setOfferData({
      ...offerData,
      products: products
    })
    return calculateProductSendAmount()
  }

  const calculateProductOfferAmount = () => {
    let amount = formatMultiplyValue(quantity, currentProduct.value)
    setAmountProductOffer(formatValueToMoney(amount))
  }

  const calculateProductSendAmount = () => {
    let amount = 0;
    offerData.products.map((product) =>(
      amount += formatMultiplyValue(product.quantity, product.value)
    ))

    setAmountProductSend(formatValueToMoney(amount))
  }

  const sendOffer = async () => {
    const { description, products } = offerData
    if(validateInput(description, products)) {
      const productSend = products.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
        }
      })
      const payload = {
        product_id: productId,
        quantity: quantity,
        description,
        products: productSend
      }
      const newOffer = await create(payload)
      if(newOffer) {
        navigate(`/offer/${newOffer.data.id}`)
      }
    }
  }

  function validateInput (description, products) {
    let hasError = false;
    if(description === '') {
      notifyMessageWarning('A Descrição da proposta não pode ser nula')
      hasError = true;
    }
    if(products.length === 0) {
      notifyMessageWarning('Precisa adicionar produtos para troca')
      hasError = true;
    }

    if(hasError) {
      return false
    }

    return true
  }

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Oferta de troca</title>
      <div className="p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md createOffer" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
      <Row>
        <div className="product">
            <div className="image">
              {currentProduct.images && currentProduct.images.image !== "" ?
              <img src={currentProduct.images.image}  width="100%"/>
              :
              <NoImage />
              }
            </div>
            <div className="description">
              <div>
                <p><b>Descrição</b></p>
                <p>{currentProduct.description}</p>
              </div>
              <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
              <div>
                <p><b>Validade</b></p>
                <p>{ currentProduct.validity ? currentProduct.validity : 'Validade Indefinida'}</p>
              </div>
              <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
            </div>
          </div>
        <div className="info">
          <div className="title">
            Fazer oferta de troca
          </div>
          <div className="product">
          {blockOffer ?
            <Alert key={'danger'} variant={'danger'}>
              Produto não está mais disponível .
            </Alert>
            :
            <></> }
            <p className="name">{currentProduct.name}</p>
            <p className="value">R$ {currentProduct.value}</p>
            <p className="stock">Quantidade disponivel : {currentProduct.quantity}</p>
            <p className="quantity">Fazer oferta em quantos produtos</p>
            <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                <div onClick={() => { changeQuantity('minus') }} className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                <i className="fs-16 zmdi zmdi-minus"></i>
                </div>

                <input className="mtext-104 cl3 txt-center num-product" type="number"
                name="num-product" min={1} max={currentProduct.quantity} value={quantity}/>

                <div onClick={() => { changeQuantity('mor') }} className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                <i className="fs-16 zmdi zmdi-plus"></i>
                </div>
            </div>
            <p className="amount">Valor total em produtos: R$ {amountProductOffer || '0,00'}</p>
          </div>
          <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>

          <div className="products">
            <h3>Selecionar meus produtos para troca</h3>
            <div>
              <Table striped>
                <thead>
                  <tr>
                    <th width="15%">Logo</th>
                    <th>Nome</th>
                    <th>Marca</th>
                    <th>Qtd</th>
                    <th>Validade</th>
                    <th>Valor</th>
                    <th>Proposta</th>
                  </tr>
                </thead>
                <tbody>
                  {myProducts.map((product)=> {
                    return(
                      <ProductLine changeQuantity={changeQuantityProductSend} product={product} />
                    )
                  })}
                </tbody>
              </Table>
            </div>
            {blockOffer ? <></> :
            <>
              <p className="amount">Valor total em produtos: R$ {amountProductSend || '0,00'}</p>
              <Form.Group className="mb-3">
                <Form.Control disabled={loading} onChange={onChangeHandler} type="text" value={offerData.description} name="description" placeholder="Descreva sua proposta" as="textarea" rows={3} />
              </Form.Group>
              <div className="col text-center">
              <Button disabled={loading} onClick={sendOffer} variant="success">Enviar Oferta</Button>
              </div>
            </>
            }
          </div>
        </div>
      </Row>
      </div>
    </>
  );
}

export default CreateOffer;