import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossDomain: true
});

/* eslint no-param-reassign: 2 */
api.interceptors.request.use((config) => {
  return config;
});

api.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('trocaPetToken')
      Cookies.remove('trocaPetUser')
      const url = window.location.href.split('/');
      if (url[3] !== 'login' && url[3] !== '') {
        window.location.href = '/';
      }
    }


    if(error.response.status === 404) {
      return Promise.reject(error.response);
    } else {

      let listMessage = [];

      if (error.response.data) {
        const erros = Object.values(error.response.data);
        erros.forEach((element) => {
          if (element !== 'undefined') {
            listMessage.push(element);
          }
        });
      } else if (error.response.data.message) {
        listMessage = [error.response.data.message];
      } else {
        listMessage = ['Erro ao conectar ao servidor, tente novamente mais tarde'];
      }
      return Promise.reject(listMessage);
    }
  });

export default api;
