/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  React,
  useState,
  createContext,
  useContext,
} from 'react';
import authApi from 'src/services/authApi'
import { ClientContext } from './ClientContext';
import { SiteContext } from './SiteContext'

export const ProductFavoriteContext = createContext();

const ProductFavoriteProvider = (prop) => {
  const token = sessionStorage.getItem('token');
  const { children } = prop;
  const { getClientByToken, client } = useContext(ClientContext)
  const { loading, setLoading, notifyMessageSave, notifyMessageError } = useContext(SiteContext)
  const baseUrl = 'client/productFavorites';
  const [product, setProductFavorite] = useState(null);
  const [products, setProductFavorites] = useState([]);
  const [productsSettings, setProductFavoritesSettings] = useState(null);

  useEffect(() => {
    if(token) {
      getClientByToken()
    }
  }, [token])

  const getAllProductFavorites = async () => {
    return await authApi.get(`${baseUrl}/all`)
      .then((response) => {
        setProductFavoritesSettings(response.data);
        setProductFavorites(response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const create = async (product_id) => {
    if(client) {
      return await authApi.post(`${baseUrl}/create`,
        {
          product_id: product_id
        })
      .then((response) => {
        notifyMessageSave("Favorito adicionado com sucesso.")
        return response.data.id;
      })
      .catch( async (err) => {
        await setLoading(false);
        if(err.status === 401) {
          notifyMessageError('Essa função é apenas para clientes', '')
        } else {
          const erros = Object.values(err[1]);
          const errosList = erros.map((er) => {
            return `\n ${er}`
          })
          notifyMessageError(err[0], errosList)
        }
        return false;
      });
    } else {
      await setLoading(false);
      notifyMessageError('Essa função é apenas para clientes', '')
    }
  };

  const remove = async (id) => {
    if(client) {
      return await authApi.delete(`${baseUrl}/delete/${id}`)
      .then((response) => {
        notifyMessageSave("Favorito removido com sucesso.")
        getAllProductFavorites()
        return true;
      })
      .catch((err) => {
        if(err.status === 401) {
          notifyMessageError('Essa função é apenas para clientes', '')
        } else {
          const erros = Object.values(err[1]);
          const errosList = erros.map((er) => {
            return `\n ${er}`
          })
          notifyMessageError(err[0], errosList)
        }
        return false;
      });
    } else {
      notifyMessageError('Essa função é apenas para clientes', '')
    }
  };

  return (
    <ProductFavoriteContext.Provider
      value={{
        product,
        products,
        create,
        remove,
        productsSettings,
        loading,
        setProductFavorite,
        getAllProductFavorites,
      }}
    >
      { children }
    </ProductFavoriteContext.Provider>

  );
};

export default ProductFavoriteProvider;
