import { BounceLoader } from "react-spinners"

function Loader(props) {
  return (
    <>
    {props.active ?
    <div className="overlay">
      <div className="loader">
        <div className="text">Processando</div>
        <div className="spinner">
          <BounceLoader />
        </div>
      </div>
    </div>
    :
    <></>
    }
    </>
  );
}

export default Loader;