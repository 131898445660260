import { useCookies } from "react-cookie"
import { useNavigate, useParams } from "react-router-dom"
import React, { useContext, useEffect } from 'react'
import { ClientContext } from 'src/context/ClientContext'

function LoginAs() {
  const navigate = useNavigate()
  const { setClient } = useContext(ClientContext)
  const { token, user } = useParams();
  const [cookies, setCookie] = useCookies(["trocaPetToken", "trocaPetUser"])

  useEffect(()=>{
    const setData = async()=>{
      console.log(cookies)
      setCookie("trocaPetToken", token)
      setCookie("trocaPetUser", user)
      setCookie("adminLogin", true)
      setClient({
        token: token,
        user: user
      });
      navigate("/");
    }

    setData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return(<>

  </>)
}

export default LoginAs;