/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */

import { useState } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import NoImage from "src/components/templete/NoImage"
import { ClientContext } from "src/context/ClientContext"

/* eslint-disable jsx-a11y/anchor-is-valid */
function Profile () {
  const { getClientByToken, update, client } = useContext(ClientContext)
  const [image, setImage] = useState(null);
  const [inputValues, setInputValues] = useState({
    name: '',
    logo: '',
    email: '',
    phone: '',
    password: '',
    repeat: ''
  });
  const [error, setError] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    repeat: ''
  });

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    setError({...error,
      [event.target.name]: ''
    })
  };

  function resetErrors () {
    setError({
      name: null,
      email: '',
      phone: '',
      password: '',
      repeat: ''
    })
  }

  function validateInput (name, email, phone, password, repeat) {
    if(name === '') {
      setError({
        ...error,
        name: 'O Nome não pode ser nulo'
      })
      return false;
    }
    if(email === '') {
      setError({
        ...error,
        email: 'O Email não pode ser nulo'
      })
      return false;
    }
    if(phone === '') {
      setError({
        ...error,
        phone: 'O Telefone não pode ser nulo'
      })
      return false;
    }
    if(password === '') {
      setError({
        ...error,
        password: 'O Senha não pode ser nulo'
      })
      return false;
    }

    if(repeat === '') {
      setError({
        ...error,
        repeat: 'A confirmação não pode ser nula'
      })
      return false;
    }

    if(repeat !== password) {
      setError({
        ...error,
        repeat: 'A confirmação da senha tem que ser a mesma que a senha'
      })
      return false;
    }

    return true
  }

  const handleChangeFile = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setInputValues({
      ...inputValues,
      logo: event.target.files[0]
    })
  };

  async function Update() {
      const {name, email, phone, password, repeat, logo} = inputValues
      resetErrors()
      const valid = validateInput(name, email, phone, password, repeat)
      if(valid) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        if(password) {
          formData.append("password", password);
        }
        if(logo) {
          formData.append("logo", logo);
        }
        await update(client.id, formData)
        getClientByToken()
      }

  }

  useEffect(()=> {

    const loadData = async () => {
      const response = await getClientByToken();
      if (!response) {
        window.location.href = '/'
      }
    };

    loadData()

  }, [])

  useEffect(()=> {
    if(client) {
      setInputValues({
        name: client.name,
        email: client.email,
        phone: client.phone,
      })
      setImage(client.logo !== '' ? client.logo : '')
    }
  }, [client])

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Perfil</title>
     <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', maxWidth: '800px', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Meus Dados
        </h4>

        <div className="input-form image">
          <div style={{ clear: 'both'}}></div>
          <div className="how-pos4-parent">
            {image !== '' ?
            <img src={image} />
            :
            <NoImage />
            }
          </div>
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input onChange={handleChangeFile} accept="image/*" className="cl2 plh3 size-116 p-l-10 p-r-30" type="file" name="logo" />
          </div>
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input data-test="input-name" value={inputValues.name || ''} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="name" placeholder="Seu Nome"/>
          </div>
          {error.name && <span className='err'>{error.name}</span>}
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input data-test="input-email" value={inputValues.email || ''} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="email" placeholder="Seu E-mail"/>
          </div>
          {error.email && <span className='err'>{error.email}</span>}
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input data-test="input-phone" value={inputValues.phone || ''} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="phone" placeholder="Seu Telefone"/>
          </div>
          {error.phone && <span className='err'>{error.phone}</span>}
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input data-test="input-password" value={inputValues.password || ''} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="password" placeholder="Sua Senha"/>
          </div>
          {error.password && <span className='err'>{error.password}</span>}
        </div>

        <div className="input-form">
          <div className="bor8 how-pos4-parent">
            <input data-test="input-repeat" value={inputValues.repeat || ''} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="repeat" placeholder="Repita sua Senha"/>
          </div>
          {error.repeat && <span className='err'>{error.repeat}</span>}
        </div>
        <a href={undefined} data-test="btn-save" onClick={Update} className="formSubmit flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
          Enviar
        </a>
      </div>
    </>
  )
}

export default Profile