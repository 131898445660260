/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCookies } from "react-cookie"
import { useContext, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { ClientContext } from "src/context/ClientContext"
import Cookies from "js-cookie";

function ProfileMenu(props) {
  const navigate = useNavigate();
  const [emailText, setEmailText] = useState('')
  const [passwordText, setPasswordText] = useState('')
  const { loading, login } = useContext(ClientContext)
  const [cookies] = useCookies(["trocaPetToken", "trocaPetUser"])
  let user = cookies.trocaPetUser && cookies.trocaPetUser !== 'undefined'  ? cookies.trocaPetUser : null

  function handleEmailTextChange(event) {
    setEmailText(event.target.value)
  }

  function handlePasswordTextChange(event) {
    setPasswordText(event.target.value)
  }

  async function singup () {
    await login(emailText, passwordText)
  }

  async function logout () {
    Cookies.remove('trocaPetToken')
    Cookies.remove('trocaPetUser')
    window.location.href = '/'
  }

  const profileMenu = () => {
    return (
      <>
        <div className="menu">
          <div className="header">
            <h4>{user}</h4>
          </div>
          <ul>
            <li><a href="/profile">Meus Dados</a></li>
            <li><a href="/my-stores">Minhas Lojas</a></li>
            <li><a href="/my-products">Meus Produtos</a></li>
            <li><a href="/offers">Ofertas</a></li>
            <li><a href="/favorite">Favoritos</a></li>
            <li onClick={logout}><a style={{ cursor: 'pointer'}} href={undefined}>Sair</a></li>
          </ul>
        </div>
      </>
    )
  }

  function register () {
    props.setShowProfile(false)
    navigate("/register")
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      singup()
    }
  }

  const formLogin = () => {
    return (
      <>
      {loading ?
          <>
          </>
          :
        <div className="login">
          <span className="label stext-115 cl6 size-213 p-t-18">E-mail ou Telefone</span>
          <div className="input bor8 m-b-20 how-pos4-parent">
            <input data-test="input-login-email" value={emailText} onChange={handleEmailTextChange}  className="stext-111 cl2 plh3 size-116 p-l-62 p-r-30" type="text" name="email" />
          </div>
          <span className="label stext-115 cl6 size-213 p-t-18">Senha</span>
          <div className="input bor8 m-b-20 how-pos4-parent">
            <input data-test="input-login-password" onKeyPress={handleKeyPress} value={passwordText} onChange={handlePasswordTextChange}  className="stext-111 cl2 plh3 size-116 p-l-62 p-r-30" type="password" name="password" />
          </div>
          <div className="forgot">
            <a data-test="link-recovery" href="/recovery">Esqueceu sua senha ?</a>
          </div>
          <div className="buttons">
            <button data-test="btn-login-register" onClick={register} className="button flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">
              Cadastrar
            </button>
            <button data-test="input-login-singup" onClick={singup} className="button flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">
              Entrar
            </button>
          </div>
        </div>
        }
      </>
    )
  }

  return (
    <>
      {props.status ? (
      <div className="profile">
        {user && user !== undefined ? profileMenu() : formLogin()}
      </div>
      )
      : <></>}
    </>
  )
}

export default ProfileMenu;