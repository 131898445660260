/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import Form from 'react-bootstrap/Form'
import NoImage from "src/components/templete/NoImage"
import { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ProductContext } from "src/context/ProductContext"
import { CategoryContext } from "src/context/CategorieContext"
import CurrencyFormat from 'react-currency-format'
import { ClientContext } from 'src/context/ClientContext'

function Product () {
  const navigate = useNavigate();
  const { id } = useParams();
  const { branch_id } = useParams();
  const { getClientByToken } = useContext(ClientContext)
  const { getProductAuth, product, create, update } = useContext(ProductContext)
  const { getAllCategory, categories } = useContext(CategoryContext)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [inputValues, setInputValues] = useState({
    image: "",
    name: "",
    brand: null,
    description: '',
    value: "",
    quantity: "",
    no_expiration: false,
    validity: "",
    active: false
  });

  const [error, setError] = useState({
    logo: "",
    name: "",
    brand: "",
    categories: ""
  });

  const [image, setImage] = useState(null);
  const onChangeHandler = event => {
    if(event.target.name === "no_expiration") {
      setInputValues({ ...inputValues, [event.target.name]: event.target.checked });
    } else {
      setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }
    setError({...error,
      [event.target.name]: ''
    })
  };

  const handleChangeFile = (event) => {
    setImage(event.target.files[0]);
    setInputValues({
      ...inputValues,
      image: URL.createObjectURL(event.target.files[0])
    })
  };

  useEffect(() => {
    getAllCategory()
    getClientByToken()
    if(id) {
      getProductAuth(id)
    }
  },[])

  useEffect(() => {
    if(product && product.data) {
      const no_expiration = product.data.no_expiration
      let validity = null
      if(!no_expiration) {
        const [day, month, year] = product.data.validity.split('/')
        validity = new Date(+year, +month - 1, +day).toISOString().slice(0, 10)
      }

      setInputValues({
      ...inputValues,
      ...product.data,
      validity: validity,
      image : product.data.images && product.data.images.image !== '' ? product.data.images.image : ''
      })

      if(product.data.categories && categories) {
        let categoriesProduct = []
        product.data.categories.forEach((category) => {
          if(category.category_id > 0) {
            categoriesProduct.push(category.category_id)
          }
        })
        setSelectedCategories(categoriesProduct)
      }
    }
  }, [product])

  function validateInput (name, brand, value, quantity, no_expiration, validity) {
    if(name === '') {
      setError({
        ...error,
        name: 'O Nome não pode ser nulo'
      })
      return false;
    }
    if(brand === '') {
      setError({
        ...error,
        brand_id: 'A marca não pode ser nula'
      })
      return false;
    }
    if(value === '') {
      setError({
        ...error,
        value: 'O Valor não pode ser nulo'
      })
      return false;
    }
    if(quantity === '') {
      setError({
        ...error,
        quantity: 'A Quantidade não pode ser nula'
      })
      return false;
    }
    if(!no_expiration && validity === '') {
      setError({
        ...error,
        validity: 'A Validade não pode ser nula'
      })
      return false;
    }

    if(selectedCategories.length === 0) {
      setError({
        ...error,
        categories: 'Selecione ao menos uma categoria para o produto.'
      })
      return false;
    }

    return true
  }

  function resetErrors () {
    setError({
      name: null,
      brand_id: null,
      value: null,
      quantity: null,
      validity: null
    })
  }

  const save = async () => {
    const formData = new FormData();
    const {name, brand, value, quantity, validity, description, no_expiration } = inputValues
    resetErrors()
    const valid = validateInput(name, brand, value, quantity, no_expiration, validity)
    if(valid) {
      var valueUnformat = value.replace('R$','').replace(',','').replace('.', '');
      valueUnformat = parseInt(valueUnformat) / 100;
      formData.append("name", name);
      formData.append("brand", brand);
      formData.append("value", valueUnformat);
      formData.append("description", description);
      formData.append("quantity", quantity);
      formData.append("no_expiration", Boolean(no_expiration) ? 1 : 0);
      if(!no_expiration) {
        formData.append("validity", validity.toString());
      }
      formData.append("images[]", image);
      formData.append("categories", selectedCategories);

      if(id) {
        formData.append("branch_id", inputValues.branch_id);
        const response = await update(id, formData)
        if(response) {
          navigate(`/my-products`)
        }
      } else {
        formData.append("branch_id", branch_id);
        const response = await create(formData)
        if(response) {
          navigate(`/my-products`)
        }
      }
    }
  }

  const listCategories = () => {
    if(categories) {
      return categories.map((category) => {
        let checked = selectedCategories.includes(category.id)
        return(
          <div className="mb-1" style={{ margin: '20px'}}>
            <Form.Check
              inlines
              onClick={()=>{ handleCategory(category.id)}}
              checked={checked}
              label={category.name}
              name={category.name}
              type="checkbox"
            />
          </div>
        )
      })
    }
  }

  const handleCategory = (id) => {
    const selected = []

    selectedCategories.forEach((element) => {
        selected.push(element)
    })

    if(!selected.includes(id)) {
      selected.push(id)
    } else {
      selected.pop(id)
    }

    setSelectedCategories(selected)
  }

  return (
    <>
      <title>{process.env.REACT_APP_NAME} - {id ? 'Edição' : 'Cadastro'} Produto</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h1>{id ? 'Edição' : 'Cadastro'} Produto</h1>
      <Form>
        <div className="input-form image">
          <div style={{ clear: 'both'}}></div>
          <div className="how-pos4-parent">
            {inputValues.image !== '' ?
            <img src={inputValues.image} />
            :
            <NoImage />
            }
          </div>
        </div>

        <Form.Group className="mb-3">
          <Form.Label>Imagem</Form.Label>
          <Form.Control onChange={handleChangeFile} type="file" name="image" placeholder="Selecione a imagem" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Nome</Form.Label>
          <Form.Control data-test="input-name" onChange={onChangeHandler} type="text" value={inputValues.name} name="name" placeholder="Nome" />
          {error.name && <span className='err'>{error.name}</span>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Marca</Form.Label>
          <Form.Control data-test="input-brand" onChange={onChangeHandler} type="text" value={inputValues.brand} name="brand" placeholder="Marca" />
          {error.brand && <span className='err'>{error.brand}</span>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor</Form.Label>
          <p><i><b>Sugerimos que todos utilizem como referência o preço de custo. Tabela do fornecedor sem desconto.</b></i></p>
          <CurrencyFormat
            name="value"
            data-test="input-value"
            className='form-control'
            onChange={onChangeHandler}
            decimalScale={2}
            decimalSeparator=","
            fixedDecimalScale
            placeholder="R$ 0,00"
            prefix="R$ "
            value={inputValues.value}
            thousandSeparator="." />
          {error.value && <span className='err'>{error.value}</span>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Quantidade</Form.Label>
          <Form.Control data-test="input-quantity" onChange={onChangeHandler} type="number" value={inputValues.quantity} name="quantity"  placeholder="Quantidade" />
          {error.quantity && <span className='err'>{error.quantity}</span>}
        </Form.Group>

        <Form.Group style={{ marginLeft: '20px'}} className="mb-3">
          <Form.Check data-test="input-expiration" name='no_expiration' checked={inputValues.no_expiration} onChange={onChangeHandler} type="checkbox" label="Validade indefinida" />
        </Form.Group>

        {inputValues.no_expiration ?
        <></>
        :
        <>
          <Form.Group className="mb-3">
            <Form.Label>Data de Validade</Form.Label>
            <Form.Control data-test="input-validity" onChange={onChangeHandler} type="date" value={inputValues.validity} name="validity"  placeholder="Validade" />
            {error.validity && <span className='err'>{error.validity}</span>}
          </Form.Group>
        </>
        }

        <Form.Group className="mb-3">
          <Form.Label>Categoria do Produto</Form.Label>
          <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              border: 'solid 1px silver',
              padding: '10px'
            }}>
            { listCategories() }
          </div>
          {error.categories && <span className='err'>{error.categories}</span>}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Descrição</Form.Label>
          <Form.Control data-test="input-description" onChange={onChangeHandler}  as="textarea" rows={3} type="text" value={inputValues.description} name="description" placeholder="Descrição do Produto" />
          {error.description && <span className='err'>{error.description}</span>}
        </Form.Group>

        <a href={undefined} data-test="btn-save" onClick={save} className="formSubmit flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
          Salvar
        </a>
      </Form>
      </div>
    </>
  )
}

export default Product