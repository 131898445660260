import { useNavigate } from "react-router-dom"

/* eslint-disable jsx-a11y/anchor-is-valid */
function Store(props) {
  const navigate = useNavigate()

  const showStore = (id) => {
    navigate(`/store/${id}`)
  }

  return (
    <>
      <div className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item Fruits">
        <div className="block2">
          <div className="block2-pic hov-img0 center">
              { props.branch.logo && props.branch.logo !== '' ?
              <img src={props.branch.logo} alt="IMG-PRODUCT"/>
              :
              <img src={`${process.env.REACT_APP_APP_URL}assets/images/no-image.png`} alt="IMG-PRODUCT"/>
              }
              <a onClick={() => {showStore(props.branch.id)}}
              className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
              Vizualizar
              </a>
          </div>
          <div className="block2-txt flex-w flex-t p-t-14">
            <div className="block2-txt-child1 flex-col-l ">
              <a href={undefined} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                {props.branch.fantasy_name}
              </a>

              <span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                {props.branch.city} - {props.branch.district}
              </span>
              <span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                Produtos : {props.branch.products}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Store;