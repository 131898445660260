/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
import { useEffect, useState } from "react"
import { useContext } from "react"
import { ProductFavoriteContext } from "src/context/ProductFavoriteContext"

/* eslint-disable jsx-a11y/anchor-is-valid */
function ProductShop(props) {
  const [favoriteId, setFavoriteId] = useState(0)
  const [favorite, setFavorite] = useState(false)
  const { create, remove } = useContext(ProductFavoriteContext)

  const clickFavorite = async () => {
    if(favorite) {
      const response = await remove(favoriteId)
      if(response) {
        setFavorite(false)
      }
    } else {
      const response = await create(props.product.id)
      if(response > 0) {
        setFavorite(true)
        setFavoriteId(response)
      }
    }
  }

  useEffect(() => {
    if(props.product) {
      setFavoriteId(props.product.favorite)
      if(props.product.favorite > 0) {
        setFavorite(true)
      }
    }
  },[props])

    return (
      <>
        <div className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item Fruits">
          <div className="block2">
            <div className="block2-pic hov-img0 center">
                { props.product.images && props.product.images.image !== '' ?
                <img style={{margin: 'auto', height: '250px', width:'auto'}} className={'img-fluid'} src={props.product.images.image} alt="IMG-PRODUCT"/>
                :
                <img style={{margin: 'auto', height: '250px', width:'auto'}} src='assets/images/no-image.png' alt="IMG-PRODUCT"/>
                }
                <a onClick={() => {props.showModal(props.product.id)}}
                className="btn-visualizar btn-visualizar block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                Vizualizar
                </a>
            </div>
            <div className="block2-txt flex-w flex-t p-t-14">
							<div className="block2-txt-child1 flex-col-l ">
								<a href={undefined} className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
									{props.product.name}
								</a>

                <span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                  {props.product.branch.fantasy_name}
                </span>

                <span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                 <a target='_blank' href={`https://www.google.com/maps/place/${props.product.branch.district},${props.product.branch.city}`}>{props.product.branch.city} - {props.product.branch.district}</a>
                </span>
								<span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
									Disponivel : {props.product.quantity}
								</span>
								<span className="stext-105 cl3">
									R$ {props.product.value}
								</span>
							</div>

							<div className="block2-txt-child2 flex-r p-t-3">
								<a href={undefined} onClick={clickFavorite} className={`btn-addwish-b2 dis-block pos-relative js-addwish-b2 ${favorite ? 'js-addedwish-b2' : ''}`}>
									<img className="icon-heart1 dis-block trans-04" src='assets/images/icons/icon-heart-01.png' alt="ICON"/>
									<img className="icon-heart2 dis-block trans-04 ab-t-l" src='assets/images/icons/icon-heart-02.png' alt="ICON"/>
								</a>
							</div>
						</div>
          </div>
        </div>
      </>
    )
}

export default ProductShop;