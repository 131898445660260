/* eslint-disable jsx-a11y/alt-text */
import NoImage from "../templete/NoImage"
import { useNavigate } from "react-router-dom"

function OfferNotification (props) {
  const navigate = useNavigate();
  const openOffer = (id, type) => {
      navigate(`/offer/${id}`)
  }

  return (
    <>
    {props.notifications.length > 0 ?

    props.notifications.map((offer) => {
      return (
        <div key={offer.id} onClick={() => { openOffer(offer.offer_id, offer.type)}} className="notification">
          <div className="image">
            {offer.image !== '' ?
            <>
            <img src={offer.image} width="100%"/>
            </>
            :
            <NoImage />
            }

          </div>
          <div className="text">
            <div className="title">{offer.title}</div>
            <div className="description">{offer.description}</div>
          </div>
        </div>
        )
    })
    :
    <>
      <div className="empty">Sem notificações</div>
    </>
    }
    </>
  )
}

export default OfferNotification