/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react"
import { useContext, useEffect } from "react"
import NoImage from "src/components/templete/NoImage"
import { SiteContext } from "src/context/SiteContext"
import { OfferContext } from "src/context/OfferContext"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, Button, Form, Row, Table } from "react-bootstrap"
import { ClientContext } from "src/context/ClientContext"
import ProductLine from 'src/components/offer/ProductLine'
import { ProductContext } from "src/context/ProductContext"
import {formatMultiplyValue, formatValueToMoney} from "src/helpers/helpers"

function RecreateOffer() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { getClientByToken, client } = useContext(ClientContext)
  const { loading, create, getOffer, offer } = useContext(OfferContext)
  const { notifyMessageWarning } = useContext(SiteContext)
  const { getAllProductsByClient, product, myProducts } = useContext(ProductContext)
  const [currentProduct, setCurrentProduct] = useState({
    image: "",
    name:"",
    value: "",
    description: "",
    quantity: 0
  })
  const [quantity, setQuantity] = useState(0)
  const [amountProductOffer, setAmountProductOffer] = useState(0)
  const [amountProductSend, setAmountProductSend] = useState(0)
  const [offerData, setOfferData] = useState({
    description: '',
    products: []
  });
  const [currentOffer, setCurrentOffer] = useState({
    product_id: null,
    quantity: 0
  })
  const [offerValid, setOffervalid] = useState(true)

  let ownerOffer
  let clientResponse

  useEffect(() => {
    if(id) {
      (async() => {
        clientResponse = await getClientByToken()
        const offer = await getOffer(id)
        setCurrentOffer(offer)
        const reject = offer.product_owner_status === 'R' || offer.offer_owner_status === 'R'
        ownerOffer = clientResponse && clientResponse.id === offer.client_id
        if(!ownerOffer || !reject) {
          navigate('/')
        }

        if(offer) {
          let products = [];
          setCurrentProduct(offer.product)
          setQuantity(offer.quantity)
          setAmountProductOffer(currentProduct.value)
          setAmountProductSend(offer.amount_products)
          offer.product_offer.map((productOffer) => (
            products.push({
              id: productOffer.product_id,
              quantity: productOffer.quantity,
              value: productOffer.product.value
            })
          ))
          setOfferData({
            products: products
          })
        } else {
          navigate('/')
        }
      })()
    }
  },[id])

  useEffect(()=>{
    if(client) {
      getAllProductsByClient('', client.id)
    }
  },[client])

  useEffect(() => {
    calculateProductOfferAmount()
  },[quantity])

  useEffect(() => {
    if(offerData) {
      calculateProductSendAmount()
    }
  },[offerData])

  useEffect(() => {
    if(product) {
      validateOffer()
    }
  },[product])

  const onChangeHandler = event => {
    setOfferData({ ...offerData, [event.target.name]: event.target.value });
  };

  const changeQuantity = (type) => {
    let value = quantity
    if(type === 'mor') {
      value ++
      if(value > currentProduct.quantity) {
        value = currentProduct.quantity
        notifyMessageWarning(`Este producto só tem ${currentProduct.quantity} disponível`)
      }
    } else {
      value --
      if(value <= 0) {
        value = 1
        notifyMessageWarning(`O mínimo de produto é 1`)
      }
    }
    setQuantity(value)
  }

  const changeQuantityProductSend = (product) => {
    const products = offerData.products
    products.forEach((productEach, index) => {
      if(productEach.id === product.id) {
        products.pop(index)
      }
    })
    if(product.quantity > 0) {
      products.push(product)
    }
    setOfferData({
      ...offerData,
      products: products
    })
    calculateProductSendAmount()
  }

  const calculateProductOfferAmount = () => {
    let amount = formatMultiplyValue(quantity, currentProduct.value)
    setAmountProductOffer(formatValueToMoney(amount))
  }

  const calculateProductSendAmount = () => {
    let amount = 0;
    offerData.products.map((product) =>(
      amount += formatMultiplyValue(product.quantity, product.value)
    ))

    setAmountProductSend(formatValueToMoney(amount))
  }

  const sendOffer = async () => {
    const { description, products } = offerData
    validateInput(description, products)
    const productSend = products.map((product) => {
      return {
        id: product.id,
        quantity: product.quantity,
      }
    })
    const payload = {
      product_id: currentOffer.product_id,
      quantity: quantity,
      description,
      products: productSend
    }
    const newOffer = await create(payload)
    navigate(`/offer/${newOffer.data.id}`)
  }

  function validateInput (description, products) {
    let hasError = false;
    if(description === '') {
      notifyMessageWarning('A Descrição da proposta não pode ser nula')
      hasError = true;
    }
    if(products.length === 0) {
      notifyMessageWarning('Precisa adicionar produtos para troca')
      hasError = true;
    }

    if(hasError) {
      return false
    }

    return true
  }

  function findProduct(product, productOffer) {
    if(product.id === productOffer.product_id) {
      return true
    }
  }

  const validateOffer = () => {
    if(product.data.quantity < currentOffer.quantity) {
      setOffervalid(false)
    }
  }

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Oferta de troca</title>
      <div className="p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md createOffer" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
      {!offerValid ?
      <Alert variant={'danger'}>
        {`Não é possível refazer a oferta, não existe mais itens disponivel do produto ${currentProduct.name} para troca`}
      </Alert>
      :
      <></>
      }
      <Row>
        <div className="product">
            <div className="image">
              {currentProduct.images && currentProduct.images.image !== "" ?
              <img src={currentProduct.images.image}  width="100%"/>
              :
              <NoImage />
              }
            </div>
            <div className="description">
              <div>
                <p>Descrição</p>
                <p>{currentProduct.description}</p>
              </div>
              <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
              <div>
                <p>Validade</p>
                <p>{currentProduct.validity}</p>
              </div>
              <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
              <div>
                <p>Validade</p>
                <p>Ração coca cola 10kg com alto teor de proteina validade alta</p>
              </div>
            </div>
        </div>
        <div className="info">
          <div className="title">
            Refazer oferta de troca
          </div>
          <div className="product">
            <p className="name">{currentProduct.name}</p>
            <p className="value">R$ {currentProduct.value}</p>
            <p className="stock">Quantidade disponivel : {currentProduct.quantity}</p>
            <p className="quantity">Fazer oferta em quantos produtos</p>
            <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                <div onClick={() => { changeQuantity('minus') }} className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                <i className="fs-16 zmdi zmdi-minus"></i>
                </div>

                <input className="mtext-104 cl3 txt-center num-product" type="number"
                name="num-product" min={1} max={currentProduct.quantity} value={quantity}/>

                <div onClick={() => { changeQuantity('mor') }} className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                <i className="fs-16 zmdi zmdi-plus"></i>
                </div>
            </div>
            <p className="amount">Valor total em produtos: R$ {amountProductOffer || '0,00'}</p>
          </div>
          <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>

          <div className="products">
            <h3>Selecionar meus produtos para troca</h3>
            <div>
              <Table striped>
                <thead>
                  <tr>
                    <th width="15%">Logo</th>
                    <th>Nome</th>
                    <th>Marca</th>
                    <th>Qtd</th>
                    <th>Validade</th>
                    <th>Valor</th>
                    <th>Proposta</th>
                  </tr>
                </thead>
                <tbody>
                  {myProducts.map((product)=> {
                    let productOffer = offer.product_offer.find(productOffer => findProduct(product, productOffer))
                    let quantity = productOffer ? productOffer.quantity : 0
                    return(
                      <ProductLine quantity={quantity} changeQuantity={changeQuantityProductSend} product={product} />
                    )
                  })}
                </tbody>
              </Table>
            </div>
            <p className="amount">Valor total em produtos: R$ {amountProductSend || '0,00'}</p>
            {offerValid ?
            <>
              <Form.Group className="mb-3">
                <Form.Control disabled={loading} onChange={onChangeHandler} type="text" value={offerData.description} name="description" placeholder="Descreva sua proposta" as="textarea" rows={3} />
              </Form.Group>
              <div className="col text-center">
              <Button disabled={loading} onClick={sendOffer} variant="success">Enviar Oferta </Button>
              </div>
            </>
            :
            <></>
            }
          </div>
        </div>
      </Row>
      </div>
    </>
  );
}

export default RecreateOffer;