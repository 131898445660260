/* eslint-disable jsx-a11y/anchor-is-valid */
function Banners() {
    return(
        <>
        {/* <!-- >>>>>>>>>>>>>>>>>>> Banner part start >>>>>>>>>>>>>>>>>>>--> */}
        <div className="sec-banner bg0 p-t-80 p-b-50">
        <div className="container">
            <div className="row">
            <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
                {/* <!-- Block1 --> */}
                <div className="block1 wrap-pic-w">
                <img src='assets/images/sub-banners/1.jpg'  alt="IMG-BANNER"/>

                <a href="./about"
                    className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                    <div className="block1-txt-child1 flex-col-l">
                    {/* <span className="block1-name ltext-102 trans-04 p-b-8">
                        Get extra 50% OFF
                    </span>

                    <span className="block1-info stext-102 trans-04">
                        Everyday Fresh
                    </span> */}
                    </div>

                    <div className="block1-txt-child2 p-b-4 trans-05">
                    <div className="block1-link stext-101 cl0 trans-09">
                        Saiba Mais
                    </div>
                    </div>
                </a>
                </div>
            </div>

            <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
                {/* <!-- Block1 --> */}
                <div className="block1 wrap-pic-w">
                <img src='assets/images/sub-banners/2.jpg' alt="IMG-BANNER"/>

                <a href="./pet-market"
                    className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                    <div className="block1-txt-child1 flex-col-l">
                    {/* <span className="block1-name ltext-102 trans-04 p-b-8">
                        Hot This Week
                    </span>

                    <span className="block1-info stext-102 trans-04">
                        $15.55 Off Special Flavour
                    </span> */}
                    </div>

                    <div className="block1-txt-child2 p-b-4 trans-05">
                    <div className="block1-link stext-101 cl0 trans-09">
                        Saiba Mais
                    </div>
                    </div>
                </a>
                </div>
            </div>

            <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
                {/* <!-- Block1 --> */}
                <div className="block1 wrap-pic-w">
                <img src='assets/images/sub-banners/3.jpg' alt="IMG-BANNER"/>

                <a href="./bath-groom"
                    className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                    <div className="block1-txt-child1 flex-col-l">
                    {/* <span className="block1-name ltext-102 trans-04 p-b-8">
                        Avocado weekend
                    </span>

                    <span className="block1-info stext-102 trans-04">
                        Special 25% OFF
                    </span> */}
                    </div>

                    <div className="block1-txt-child2 p-b-4 trans-05">
                    <div className="block1-link stext-101 cl0 trans-09">
                        Saiba Mais
                    </div>
                    </div>
                </a>
                </div>
            </div>
            </div>
        </div>
        </div>
        {/* <!-- >>>>>>>>>>>>>>>>>>> Banner part end >>>>>>>>>>>>>>>>>>>--> */}
        </>
    )
}

export default Banners;