/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react"
import { useContext } from "react"
import { SiteContext } from "src/context/SiteContext"
import NoImage from "src/components/templete/NoImage"
import { useEffect } from "react"
function ProductLine(props) {
  const { notifyMessageWarning } = useContext(SiteContext)
  const [quantity, setQuantity] = useState(0)
  const limit = props.product.quantity
  const quantityStart = props.quantity || 0

  useEffect(() =>{
    setQuantity(quantityStart)
  },[])

  const changeQuantity = async (type) => {
    let value = quantity
    if(type === 'mor') {
      value ++
      if(value > (limit + quantityStart)) {
        value = (limit + quantityStart)
        notifyMessageWarning(`Voce só tem ${(limit + quantityStart)} deste produto`)
      }
    } else {
      value --
      if(value < 0) {
        value = 0
      }
    }
    setQuantity(value)
    await props.changeQuantity({
      id: props.product.id,
      quantity: value,
      value: props.product.value
    })
  }

  return (
    <>
      <tr className={quantity > 0 ? 'bg-info text-light' : ''}>
        <td>
        {props.product.images && props.product.imagesimage !== '' ?
          <img src={props.product.images.image} className='img-fluid shadow-4' width="100%"/>
          :
          <NoImage />
        }
        </td>
        <td>{props.product.name}</td>
        <td>{props.product.brand}</td>
        <td>{(props.product.quantity + quantityStart) - quantity}</td>
        <td>{props.product.validity}</td>
        <td>R$ {props.product.value}</td>
        <td>
          <div className="wrap-num-product flex-w m-r-20 m-tb-10">
              <div onClick={() => { changeQuantity('minus') }} className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
              <i className="fs-16 zmdi zmdi-minus"></i>
              </div>

              <input className="mtext-104 cl3 txt-center num-product" type="number"
              name="num-product" min={0} max={props.product.quantity} value={quantity}/>

              <div onClick={() => { changeQuantity('mor') }} className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
              <i className="fs-16 zmdi zmdi-plus"></i>
              </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default ProductLine;