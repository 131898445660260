import { useContext } from "react"
import Loader from "src/components/templete/Loader"
import { Outlet } from "react-router-dom"
import { SiteContext } from "src/context/SiteContext"

function CustomTemplete() {
  const { loading } = useContext(SiteContext)
  return (
    <>
      <Loader active={loading} />
      <Outlet />
    </>
  );
}

export default CustomTemplete;
