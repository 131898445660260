/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react"
import { ProductContext } from "src/context/ProductContext"
import ProductShop from "src/components/templete/ProductShop"

function ProductList (props) {
  const { getMorProducts, productsSettings } = useContext(ProductContext)
  function listProducts () {
    const list = []
    props.products.forEach((product) =>  {
      list.push (
        <ProductShop key={product.id} product={product} showModal={props.openModalProduct} />
      )
    })
    return list
  }

  return (
    <>
      {/* <!-- >>>>>>>>>>>>>>>>>>> Product part start >>>>>>>>>>>>>>>>>>>--> */}

            <div className="row isotope-grid">
                {props.products && props.products.length > 0 ?
                  <>
                  {listProducts()}
                  {(productsSettings && productsSettings.links && productsSettings.links.next ?
                  <div className="flex-c-m flex-w w-full p-t-45">
                    <a onClick={getMorProducts} href={undefined} className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                      Carregar mais
                    </a>
                  </div>
                  :
                  <>
                  </>
                  )}
                  </>
                  :
                  <></>
                }
            </div>

      {/* <!-- >>>>>>>>>>>>>>>>>>> Product part end >>>>>>>>>>>>>>>>>>>--> */}
    </>
  )
}

export default ProductList