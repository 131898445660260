/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { ProductContext } from "src/context/ProductContext"
import ProductList from "src/components/templete/ProductList"
import { CategoryContext } from "src/context/CategorieContext"
import PlaceHolderProducts from "src/components/placeholders/PlaceHolderProducts"
import ProductFilter from "src/components/templete/ProductFilter"
import { CityContext } from "src/context/CityContext"

function Products()  {
  const { openModalProduct } = useOutletContext()
  const { cities, getAllCity } = useContext(CityContext)
  const { loading, products, getAllProducts } = useContext(ProductContext)
  const { categories, getAllCategory } = useContext(CategoryContext)
  useEffect(() => {
    getAllProducts()
    getAllCategory()
    getAllCity()
  }, [])
  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Produtos</title>
    <section className="bg0 p-t-23 p-b-140">
      <div className="list-products" style={{minHeight: '50vh'}}>
        <div className="container">
          <div className="p-b-10">
            <h3 data-test="products-title" className="ltext-103 cl5">
              Produtos para troca
            </h3>
          </div>
          <ProductFilter categories={categories} cities={cities} />
          {loading ?
              <PlaceHolderProducts />
            :
          <ProductList
            loading={loading}
            products={products}
            categories={categories}
            openModalProduct={openModalProduct}
          />
          }
        </div>
      </div>
    </section>
    </>
  );
}
export default Products;