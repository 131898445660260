function About()  {
  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Sobre Nós</title>
    {/* <!-- >>>>>>>>>>>>>>>>>>> Content part start >>>>>>>>>>>>>>>>>>>--> */}
    <section className="bg0 p-t-75 p-b-120">
      <div className="container">
        <div className="row p-b-148">
          <div className="col-md-7 col-lg-8">
            <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
              <h3 data-test="about-title" className="mtext-111 cl2 p-b-16">
                Sobre Nós
              </h3>

              <p className="stext-113">TROCAPET &eacute; uma ferramenta de troca de produtos entre lojas do segmento Pet.</p>
              <p className="stext-113">Produtos encalhados na loja? Troque com outro lojista!</p>
              <p className="stext-113">Cliente pediu um produto e voc&ecirc; n&atilde;o tem na loja? Procure um outro lojista que est&aacute; oferecendo este produto para troca!</p>
              <p className="stext-113">Lojas do segmento Pet trabalham com uma infinidade de produtos e &eacute; inevit&aacute;vel que alguns fiquem encalhados ou que a loja n&atilde;o tenha a pronta entrega tudo que os clientes pedem.&nbsp;</p>
              <p className="stext-113">TROCAPET &eacute; a ferramenta perfeita para resolver isso e muitos outros problemas!</p>
              <p className="stext-113"><br/></p>
              <p className="stext-113">- Voc&ecirc; comprou e achou que venderia f&aacute;cil;&nbsp;</p>
              <p className="stext-113">- Aproveitou uma promo&ccedil;&atilde;o e comprou muito mais do que precisava;</p>
              <p className="stext-113">- Um novo produto entrou no mercado e os clientes deixaram de lado aquele produto que sempre vendeu bem;</p>
              <p className="stext-113">- Um produto parou de vender pois uma loja pr&oacute;xima consegue fazer um pre&ccedil;o melhor que o seu;</p>
              <p className="stext-113">- N&atilde;o quer mais trabalhar com determinado fornecedor e quer se desfazer dos produtos;</p>
              <p className="stext-113">- Tem produtos quem est&atilde;o prestes a vencer e precisa vender logo;</p>
              <p className="stext-113"><br/></p>
              <p className="stext-113"><b>NESTES CASOS, PRODUTO PARADO &Eacute; DINHEIRO PARADO!&nbsp;</b></p>
              <p className="stext-113"><b>O MERCADO N&Atilde;O PERDOA LOJA QUE DEMORA A GIRAR O ESTOQUE!</b></p>
              <p className="stext-113"><br/></p>
            </div>
          </div>

          <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div className="how-bor1 ">
              <div className="hov-img0">
                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/publicity/sobre-nos.jpg`} alt="IMG"/>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
            <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
              <h3 className="mtext-111 cl2 p-b-16">
                Outras vantagens!
              </h3>

              <p className="stext-113"><br/></p>
              <p className="stext-113">- Voc&ecirc; quer comprar pouco e n&atilde;o quer fazer uma compra com o valor m&iacute;nimo que o fornecedor pede;</p>
              <p className="stext-113">- N&atilde;o consegue esperar a pr&oacute;xima entrega do fornecedor para atender um cliente;</p>
              <p className="stext-113">- O vendedor sumiu e precisa do produto logo para repor o estoque;</p>
              <p className="stext-113">- Quer testar um produto novo na loja e n&atilde;o quer comprar muito direto com fornecedor;</p>
              <p className="stext-113">- Quer colocar uma linha nova de produtos e n&atilde;o acha um fornecedor que atenda a sua regi&atilde;o;</p>
              <p className="stext-113">- &Eacute; muito importante ter contato com outras lojas e assim se ajudarem.</p>
              <p className="stext-113"><br/></p>
              <p className="stext-113">NESTES CASOS, PESQUISE POR LOJAS QUE TENHAM PRODUTOS QUE LHE INTERESSAM E ESTEJAM OFERECENDO PARA TROCA!&nbsp;</p>
              <p className="stext-113">N&Atilde;O PERCA CLIENTE. SE COMPRAR EM OUTRA LOJA, ELE N&Atilde;O VOLTA MAIS!</p>
              <p className="stext-113"><br/></p>
              <p className="stext-113">TROCAPET &eacute; uma ferramenta muito simples de usar, clique e saiba mais!</p>
              <p className="stext-113"><br/></p>
              <p className="stext-113"><button className="button flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04">Saiba Mais</button></p>
            </div>
          </div>

          <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
            <div className="how-bor2">
              <div className="hov-img0">
                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/publicity/sobre-nos-2.jpg`} alt="IMG"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- >>>>>>>>>>>>>>>>>>> Content part end >>>>>>>>>>>>>>>>>>>--> */}
    </>
  );
}
export default About;