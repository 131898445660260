import { useContext } from 'react'
import {
  React,
  useState,
  createContext,
} from 'react'
import { useCookies } from 'react-cookie'
import api from 'src/services/api'
import authApi from 'src/services/authApi'
import { SiteContext } from './SiteContext'

export const BranchContext = createContext()

const BranchProvider = (prop) => {
  const [cookies] = useCookies(["trocaPetToken"])
  const {loading, setLoading, notifyMessageSave, notifyMessageError } = useContext(SiteContext)
  const baseUrlAuth = '/client/branch'
  const baseUrl = '/branch'
  const { children } = prop
  const [branch, setBranch] = useState(null)
  const [branchs, setBranchs] = useState([])
  const [branchsSettings, setBranchsSettings] = useState(null)

  const getBranch = async (id) => {
    setLoading(true)
    return await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setBranch(response.data.data)
        setLoading(false)
        return response.data.data
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  const getBranchAuth = async (id) => {
    setLoading(true)
    return await authApi.get(`${baseUrlAuth}/get/${id}`)
      .then((response) => {
        setBranch(response.data.data)
        setLoading(false)
        return response.data.data
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  const getAllBranchAuth = async () => {
    setLoading(true)
    await authApi.get(`${baseUrlAuth}/all`)
      .then((response) => {
        setBranchsSettings(response.data)
        setBranchs(response.data.data)
        setLoading(false)
        return true
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  const getAllBranch = async () => {
    setLoading(true)
    await api.get(`${baseUrl}/all`,{
      headers:{
        Authorization: 'Bearer '+cookies.trocaPetToken
      }
    })
      .then((response) => {
        setBranchsSettings(response.data)
        setBranchs(response.data.data)
        setLoading(false)
        return true
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  const getAllBranchNoPaginate = async () => {
    setLoading(true)
    await authApi.get(`${baseUrlAuth}/noPaginate/all/10000`)
      .then((response) => {
        setBranchsSettings(response.data)
        setBranchs(response.data.data)
        setLoading(false)
        return true
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  const getMorBranchs = async () => {
    setLoading(true);
    await api.get(`${branchsSettings.links.next}`)
      .then((response) => {
        setBranchsSettings(response.data)
        let currentProducts = branchs
        response.data.data.forEach((product) => {
          currentProducts.push(product)
        })

        setBranchs(currentProducts)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  }

  const searchBranchs = async (name, city) => {
    setLoading(true);

    if(name === '' && city === '') {
      getAllBranch()
    } else {

      await api.post(`${baseUrl}/search`,
        {
          param:name,
          city: city
        }
      )
      .then((response) => {
        setBranchsSettings(response.data);
        setBranchs(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    }
  };

  const create = async (formData) => {
    setLoading(true)
      await authApi.post(`${baseUrlAuth}/create`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Cadastro realizado com sucesso.")
        return true
      })
      .catch((err) => {
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false
      })

  }

  const update = async (id, formData) => {
    setLoading(true)
      await authApi.post(`${baseUrlAuth}/update/${id}`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Atualização realizado com sucesso.")
        return true
      })
      .catch((err) => {
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false
      })

  }

  const softDelete = async (id) => {
    setLoading(true)
      await authApi.delete(`${baseUrlAuth}/delete/${id}`)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Exclusão realizada com sucesso.")
        return true
      })
      .catch((err) => {
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false
      })

  }

  return (
    <BranchContext.Provider
      value={{
        branch,
        branchs,
        branchsSettings,
        getAllBranchNoPaginate,
        loading,
        getAllBranch,
        getMorBranchs,
        searchBranchs,
        getAllBranchAuth,
        setBranch,
        create,
        update,
        softDelete,
        getBranch,
        getBranchAuth
      }}
    >
      { children }
    </BranchContext.Provider>

  )
}

export default BranchProvider
