/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
import { useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { BranchContext } from "src/context/BranchContext"
import NoImage from "src/components/templete/NoImage"
import ModalConfirm from "src/components/templete/ModalConfirm"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'

/* eslint-disable jsx-a11y/anchor-is-valid */
function MyStores () {
  const [branchId, setBranchId] = useState(0);
  const [showModal, setModalShow] = useState(false);
  const { getAllBranchAuth, branchs, softDelete } = useContext(BranchContext)

  useEffect(()=> {
    getAllBranchAuth();
  }, [])

  const modalDelete = (id) => {
    setBranchId(id)
    setModalShow(true);
  }

  const deleteBranch = async () => {
    setModalShow(false)
    await softDelete(branchId)
    getAllBranchAuth()
  }

  const cardsBranchs = () => {
    return (
      branchs.map((branch)=> {
        return(
            <Card key={branch.id} style={{ width: '100%', marginBottom: 10 }}>
              <Card.Img variant="top" src={branch.logo}/>
              <Card.Body>
                <Card.Title><h2>{branch.fantasy_name}</h2></Card.Title>
                <Card.Text style={{ padding: 10 }}>
                  <p style={{ padding: 5, fontSize: '17px' }}><FontAwesomeIcon icon={faLocationDot} /> Endereço : {branch.city}, {branch.district}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}><FontAwesomeIcon icon={faBoxesStacked} /> Produtos : {branch.products}</p>
                </Card.Text>
                <a href={`my-store/${branch.id}`}><Button variant="primary">Visualizar</Button></a>
                <a onClick={() => {modalDelete(branch.id)}} href={undefined}><Button variant="danger">Excluir</Button></a>
              </Card.Body>
            </Card>
        )
      })
    )
  }

  const listBranchs = () => {
    return (
      branchs.map((branch)=> {
        return(
          <tr key={branch.id}>
            <td>
              {branch.logo !== '' ?
              <img src={branch.logo} width={"100px"} />
              :
              <NoImage />
              }
            </td>
            <td>{branch.fantasy_name}</td>
            <td>{branch.city}</td>
            <td>{branch.district}</td>
            <td>{branch.products}</td>
            <td><a href={`my-store/${branch.id}`}><Button variant="primary">Visualizar</Button></a></td>
            <td><a onClick={() => {modalDelete(branch.id)}} href={undefined}><Button variant="danger">Excluir</Button></a></td>
          </tr>
        )
      })
    )
  }

  useEffect(() => {
    if(branchs) {
      listBranchs()
    }
  },[branchs])


  const render = () => {
    if (window.innerWidth < 720) {
      return (cardsBranchs())
    } else {
      return (
        <Table striped>
          <thead>
            <tr>
              <th width="15%">Logo</th>
              <th>Nome</th>
              <th>Cidade</th>
              <th>Bairro</th>
              <th>Produtos</th>
              <th>Visualizar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {listBranchs()}
          </tbody>
        </Table>
      )
    }
  }


  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Minhas Lojas</title>
     <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Minhas Lojas
        </h4>
        <div  style={{ float:'right', margin: '20px'}}>
          <a data-test="btn-new" href={`/my-store`}><Button variant="primary">Cadastrar Nova</Button></a>
        </div>
        {render()}
      </div>
      <ModalConfirm title={'Excluir Loja'} description={'Tem certeza que deseja excluir essa loja, e todos seus produtos ?'} closeModal={setModalShow} active={showModal} confirm={deleteBranch}/>
    </>
  )
}

export default MyStores