import App from './App'
import ReactGA from "react-ga4"
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from "react-cookie"


if ( process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize("G-362GXMPM0R");
}
ReactDOM.render((
  <BrowserRouter>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>
), document.getElementById('root'))

const SendAnalytics = ()=> {
  if ( process.env.REACT_APP_ENV === 'production') {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }
}
reportWebVitals(SendAnalytics);