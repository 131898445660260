import { useContext, useEffect, useState } from "react"
import { ProductContext } from "src/context/ProductContext"

/* eslint-disable jsx-a11y/anchor-is-valid */
function ProductFilter (props) {
  const [searchText, setSearchText] = useState('')
  const [categories, setCategories] = useState([])
  const [cities, setCities] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const { searchProducts, searchProductsByBranch } = useContext(ProductContext)
  const [activeCategorie, setActiveCategorie] = useState(0)
  const [city, setCity] = useState("")

  useEffect(() => {
    if(props.categories && props.categories.length > 0) {
      setCategories(props.categories)
    } else {
      setCategories([])
    }
    if(props.cities && props.cities.length > 0) {
      setCities(props.cities)
    } else {
      setCities([])
    }
  },[props.categories, props.cities])

  function handleChange(event) {
    setSearchText(event.target.value)
    search(event.target.value, activeCategorie, city)
  }

  function handleChangeCity(event) {
    setCity(event.target.value)
    search(searchText, activeCategorie, event.target.value)
  }

  // function openFilter(e) {
  //   setShowFilter(showFilter => !showFilter)
  //   setShowSearch(false)
  // }

  function openSearch(e) {
    setShowSearch(showSearch => !showSearch)
    setShowFilter(false)
  }

  function filterCategorie(categorieId) {
    setActiveCategorie(categorieId)
    search(searchText, categorieId, city)
  }

  function search(product, categorie, city) {
    if(props.branch) {
      searchProductsByBranch(props.branch, product, categorie)
    } else {
      searchProducts(product, categorie, city)
    }
  }

  return (
    <>
      <div className="flex-w flex-sb-m p-b-52">
        <div className="flex-w flex-l-m filter-tope-group m-tb-10">
          <button key={0} onClick={() => { filterCategorie(0)}} className={activeCategorie === 0 ? "stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1" : 'stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5'}>
            Todas
          </button>
          {categories.map(categorie => {
            const active = (categorie.id === activeCategorie) ? 'how-active1' : ''
            return(
              <button key={categorie.id} onClick={() => { filterCategorie(categorie.id)}} className={"stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 " + active}>
                {categorie.name}
              </button>
            )
          })}
        </div>

        <div className="flex-w flex-c-m m-tb-10">
          {/* <div onClick={openFilter} className={showFilter ? "flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4 js-show-filter show-search" : "flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4 js-show-filter"}>
            <i className="icon-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-filter-list"></i>
            <i className="icon-close-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"></i>
            Filter
          </div> */}

          <div data-test="btn-product-search" onClick={openSearch} className= {showSearch ? "flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search show-search" : "flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search "}>
            <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search"></i>
            <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"></i>
            Pesquisar
          </div>
        </div>

        {/* <!-- Search product --> */}
        <div className={showSearch ? "panel-search w-full p-t-10 p-b-15" :"dis-none panel-search w-full p-t-10 p-b-15"}>
          <div style={{ display: "flex"}}>
            <div className="col-md-7">
              <div className="bor8 dis-flex p-l-15">
                <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                  <i className="zmdi zmdi-search"></i>
                </button>

                <input data-test="input-product-search" value={searchText} onChange={handleChange} className="mtext-107 cl2 size-114 plh2 p-r-15" type="text" name="search-product"
                  placeholder="Nome do Produto"/>
              </div>
            </div>

            <div className="col-md-5">
              <div className="dis-flex p-l-15" >
                <select onChange={handleChangeCity} data-test="select-product-search-city" name="search-city" className="bor8 size-114">
                  <option value="">Todas Cidades</option>
                {cities.map((city, index) => (
                  <option key={city.name}>{city.name}</option>
                ))}
                </select>
              </div>
            </div>

          </div>
        </div>


        {/* <!-- Filter --> */}
        <div className={showFilter ? "panel-filter w-full p-t-10" : "dis-none panel-filter w-full p-t-10"}>
          <div className="wrap-filter flex-w bg6 w-full p-lr-40 p-t-27 p-lr-15-sm">
            <div className="filter-col1 p-r-15 p-b-27">
              <div className="mtext-102 cl2 p-b-15">
                Sort By
              </div>

              <ul>
                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    Default
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    Popularity
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    Average rating
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                    Newness
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    Price: Low to High
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    Price: High to Low
                  </a>
                </li>
              </ul>
            </div>

            <div className="filter-col2 p-r-15 p-b-27">
              <div className="mtext-102 cl2 p-b-15">
                Price
              </div>

              <ul>
                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                    All
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    $0.00 - $50.00
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    $50.00 - $100.00
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    $100.00 - $150.00
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    $150.00 - $200.00
                  </a>
                </li>

                <li className="p-b-6">
                  <a href="#" className="filter-link stext-106 trans-04">
                    $200.00+
                  </a>
                </li>
              </ul>
            </div>

            <div className="filter-col3 p-r-15 p-b-27">
              <div className="mtext-102 cl2 p-b-15">
                Color
              </div>

              <ul>
                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#222"}}>
                    <i className="zmdi zmdi-circle"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04">
                    Black
                  </a>
                </li>

                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#4272d7"}}>
                    <i className="zmdi zmdi-circle"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                    Blue
                  </a>
                </li>

                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#b3b3b3"}}>
                    <i className="zmdi zmdi-circle"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04">
                    Grey
                  </a>
                </li>

                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#00ad5f"}}>
                    <i className="zmdi zmdi-circle"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04">
                    Green
                  </a>
                </li>

                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#fa4251"}}>
                    <i className="zmdi zmdi-circle"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04">
                    Red
                  </a>
                </li>

                <li className="p-b-6">
                  <span className="fs-15 lh-12 m-r-6" style={{color: "#aaa"}}>
                    <i className="zmdi zmdi-circle-o"></i>
                  </span>

                  <a href="#" className="filter-link stext-106 trans-04">
                    White
                  </a>
                </li>
              </ul>
            </div>

            <div className="filter-col4 p-b-27">
              <div className="mtext-102 cl2 p-b-15">
                Tags
              </div>

              <div className="flex-w p-t-4 m-r--5">
                <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                  Vegetables
                </a>

                <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                  Fruits
                </a>

                <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                  Bread
                </a>

                <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                  New
                </a>

                <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                  Sale
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductFilter