import { Button, Modal } from "react-bootstrap";

function ModalConfirm(props) {
  const handleClose = () => {
    props.closeModal(false)
  }
  const confirm = () => {
    props.confirm()
  }

  return (
    <>
      <Modal style={{ zIndex: 10000, marginTop: '15%'}} show={props.active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={confirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalConfirm;