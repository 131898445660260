export const formatMultiplyValue = (quantity, value) => {
  let formatValue = value.replace(',', '').replace('.','');
  formatValue = parseFloat(formatValue)
  formatValue = quantity * formatValue
  return formatValue
}

export const formatValueToMoney = (value) => {
  value = value.toString()
  value = value.substring(0, value.length -2)+'.'+value.substring(value.length-2, value.length)
  return parseFloat(value).toLocaleString('pt-BR',{
    minimumFractionDigits: 2
  })
}