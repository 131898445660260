/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
import { useState } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import { ProductContext } from "src/context/ProductContext"
import { ClientContext } from "src/context/ClientContext"
import { Alert } from "react-bootstrap"

/* eslint-disable jsx-a11y/anchor-is-valid */
function ModalProduct(props) {
  const { getClientByToken, client } = useContext(ClientContext)
  const { product } = useContext(ProductContext)
  const [currentProduct, setCurrentProduct] = useState({
    name: '',
    branch: {
      fantasy_name: ''
    }
  })

  useEffect(() => {
    getClientByToken()
  },[])

  useEffect(() => {
    if(product) {
      setCurrentProduct(product.data)
    }
  },[product])
  return (
    <>
      <div className={props.status ? 'wrap-modal1 js-modal1 p-t-60 p-b-20 show-modal1' : 'wrap-modal1 js-modal1 p-t-60 p-b-20 '}>
          <div className="overlay-modal1 js-hide-modal1"></div>

          <div className="container">
              <div className="bg0 p-t-60 p-b-30 p-lr-15-lg how-pos3-parent">
                  <button className="how-pos3 hov3 trans-04 js-hide-modal1">
                      <img onClick={props.closeModalProduct} src="../assets/images/icons/icon-close.png" alt="CLOSE"/>
                  </button>

                  <div className="row">
                      <div className="col-md-6 col-lg-7 p-b-30">
                          <div className="p-l-25 p-r-30 p-lr-0-lg">
                              <div className="wrap-slick3 flex-sb flex-w">
                                  <div className="wrap-slick3-dots"></div>
                                  <div className="wrap-slick3-arrows flex-sb-m flex-w"></div>

                                  <div className="slick3 gallery-lb">
                                      <div className="item-slick3" data-thumb={'image'}>
                                          <div className="wrap-pic-w pos-relative">
                                              {product && product.data.images && product.data.images.image !== '' ?
                                              <img src={product.data.images.image} alt="IMG-PRODUCT"/>
                                              :
                                              <img src='assets/images/no-image.png' alt="IMG-PRODUCT"/>
                                              }
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 col-lg-5 p-b-30">
                          <div className="p-r-50 p-t-5 p-lr-0-lg">
                              <h4 className="mtext-105 cl2 js-name-detail p-b-14">
                              {currentProduct.name}
                              </h4>

                              <p className="stext-102 cl3 p-t-23">
                              {currentProduct.description}
                              </p>

                              <span className="mtext-106 cl2">
                              R$ {currentProduct.value}
                              </span>

                              <p className="stext-102 cl3 p-t-23">
                              Quantidade : {currentProduct.quantity}
                              </p>

                              <p className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                {currentProduct.branch.fantasy_name}
                              </p>
                              <span className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                                {currentProduct.branch.city} - {currentProduct.branch.district}
                              </span>


                              {/* <div className="p-t-33">
                                  <div className="flex-w flex-r-m p-b-10">
                                      <div className="size-203 flex-c-m respon6">
                                      Weight
                                      </div>

                                      <div className="size-204 respon6-next">
                                          <div className="rs1-select2 bor8 bg0">
                                              <select className="js-select2" name="time">
                                              <option>Choose an option</option>
                                              <option>100g</option>
                                              <option>200g</option>
                                              <option>500g</option>
                                              <option>1kg</option>
                                              </select>
                                              <div className="dropDownSelect2"></div>
                                          </div>
                                      </div>
                                  </div>

                              <div className="flex-w flex-r-m p-b-10">
                                  <div className="size-203 flex-c-m respon6">
                                  Size
                                  </div>

                                  <div className="size-204 respon6-next">
                                      <div className="rs1-select2 bor8 bg0">
                                          <select className="js-select2" name="time">
                                          <option>Choose an option</option>
                                          <option>small</option>
                                          <option>medium</option>
                                          <option>large </option>
                                          </select>
                                          <div className="dropDownSelect2"></div>
                                      </div>
                                  </div>
                              </div>

                              <div className="flex-w flex-r-m p-b-10">
                                  <div className="size-204 flex-w flex-m respon6-next">
                                      <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                                          <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                                          <i className="fs-16 zmdi zmdi-minus"></i>
                                          </div>

                                          <input className="mtext-104 cl3 txt-center num-product" type="number"
                                          name="num-product" value="1"/>

                                          <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                                          <i className="fs-16 zmdi zmdi-plus"></i>
                                          </div>
                                      </div>

                                      <button
                                          className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                                          Add to cart
                                      </button>
                                  </div>
                              </div>
                              </div> */}
                              {client ?
                              <>
                                <p style={{ marginTop: '10px'}}>
                                  <a data-test="modal-product-btn-offer" href={`/make/offer/${currentProduct.id}`}>
                                    <button
                                        className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                                        Fazer Proposta
                                    </button>
                                  </a>
                                </p>
                              </>
                              :
                              <>
                              <p style={{ marginTop: '10px'}}>
                                <Alert data-test="modal-product-alert-register" key={'primary'} variant={'primary'}>
                                  Para fazer oferta você precisa estar cadastrado
                                </Alert>
                                  <a data-test="modal-product-btn-register" href={`/register`}>
                                    <button
                                        className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail">
                                        Cadastra-se
                                    </button>
                                  </a>
                                </p>
                              </>
                              }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default ModalProduct;
