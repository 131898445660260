/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect } from "react";
import { Button, Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import NoImage from "src/components/templete/NoImage";
import { OfferContext } from "src/context/OfferContext"

function ListOffers() {
  const navigate = useNavigate()
  const { offersMade, offersReceived, getAllOfferMade, getAllOfferReceived } = useContext(OfferContext)

  useEffect(()=>{
      getAllOfferMade()
      getAllOfferReceived()
  },[])

  const listOfferMade = () => {
    return (<Table striped>
      <thead>
        <tr>
          <th width="15%">Image</th>
          <th>Nome</th>
          <th>Quantidade</th>
          <th>Produtos Enviados</th>
          <th>Status</th>
          <th>Visualizar</th>
        </tr>
      </thead>
      <tbody>
        {offersMade.map((offer)=> {
          return(
            <tr>
              <td>
                {offer.product.images && offer.product.images.image !== '' ?
                <img src={offer.product.images.image} width={"100px"} />
                :
                <NoImage />
                }
              </td>
              <td>{offer.product.name}</td>
              <td>{offer.quantity}</td>
              <td>{offer.product_offer.length}</td>
              <td>{offer.status}</td>
              <td><a onClick={()=>{ editOffer(offer.id) }}><Button variant="primary">Visualizar</Button></a></td>
            </tr>
          )
        })}
      </tbody>
    </Table>)
  }

  const listOfferReceived = () => {
    return (
      <Table striped>
        <thead>
          <tr>
            <th>Image</th>
            <th>Nome</th>
            <th>Quantidade</th>
            <th>Produtos Enviados</th>
            <th>Status</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
        { offersReceived.map((offer)=> {
          return(
            <tr>
              <td>
                {offer.product.images && offer.product.images.image !== '' ?
                <img src={offer.product.images.image} width={"100px"} />
                :
                <NoImage />
                }
              </td>
              <td>{offer.product.name}</td>
              <td>{offer.quantity}</td>
              <td>{offer.product_offer.length}</td>
              <td>{offer.status}</td>
              <td><a onClick={()=>{ editOffer(offer.id) }}><Button variant="primary">Visualizar</Button></a></td>
            </tr>
          )
        })}
        </tbody>
      </Table>)
  }

  const editOffer = (id) => {
    navigate(`/offer/${id}`)
  }

  const cardOfferMade = () => {
    return (
      offersMade.map((offer)=> {
        const image = offer.product.images && offer.product.images.image !== '' ? offer.product.image : `${process.env.REACT_APP_APP_URL}assets/images/no-image.png`;
        return(
            <Card key={offer.id} style={{ width: '100%', marginBottom: 10 }}>
              <Card.Img variant="top" src={image}/>
              <Card.Body>
                <Card.Title><h2>{offer.product.name}</h2></Card.Title>
                <Card.Text style={{ padding: 10 }}>
                  <p style={{ padding: 5, fontSize: '17px' }}> Quantidade : {offer.quantity}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Produtos enviados : {offer.product_offer.length}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Status : {offer.status}</p>
                </Card.Text>
                <a onClick={()=>{ editOffer(offer.id) }}><Button variant="primary">Visualizar</Button></a>
              </Card.Body>
            </Card>
        )
      })
    )
  }

  const cardOfferReceived = () => {
    return (
      offersReceived.map((offer)=> {
        const image = offer.product.images && offer.product.images.image !== '' ? offer.product.image : `${process.env.REACT_APP_APP_URL}assets/images/no-image.png`;
        return(
            <Card key={offer.id} style={{ width: '100%', marginBottom: 10 }}>
              <Card.Img variant="top" src={image}/>
              <Card.Body>
                <Card.Title><h2>{offer.product.name}</h2></Card.Title>
                <Card.Text style={{ padding: 10 }}>
                  <p style={{ padding: 5, fontSize: '17px' }}> Quantidade : {offer.quantity}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Produtos enviados : {offer.product_offer.length}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Status : {offer.status}</p>
                </Card.Text>
                <a onClick={()=>{ editOffer(offer.id) }}><Button variant="primary">Visualizar</Button></a>
              </Card.Body>
            </Card>
        )
      })
    )
  }

  return (
    <>
      <title>{process.env.REACT_APP_NAME} - Lista de Ofertas</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Ofertas
        </h4>

        <Tabs
          defaultActiveKey="my-offer"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
           eventKey="my-offer" title="Ofertas que eu Fiz">
            {window.innerWidth < 720 ? cardOfferMade() :listOfferMade() }
            <Row>
              <Col>
                <div  style={{ float:'right', margin: '20px'}}>

                </div>
              </Col>
            </Row>
          </Tab>
          <Tab
           eventKey="offer-my-products" title="Ofertas nos meus Produtos">
            {window.innerWidth < 720 ? cardOfferReceived() :listOfferReceived() }
            <Row>
              <Col>
                <div  style={{ float:'right', margin: '20px'}}>

                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ListOffers;