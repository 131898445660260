import { useContext } from 'react';
import {
  React,
  useState,
  createContext,
} from 'react';
import authApi from 'src/services/authApi';
import { SiteContext } from './SiteContext';

export const OfferContext = createContext();

const OfferProvider = (prop) => {
  const { loading, setLoading, notifyMessageSave, notifyMessageError } = useContext(SiteContext)
  const baseUrl = '/client/offer';
  const baseUrlMyOffer = '/client/offerMyProducts';
  const { children } = prop;
  const [offer, setOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [offersMade, setOffersMade] = useState([]);
  const [offersMadeSettings, setOffersMadeSettings] = useState([]);
  const [offersReceived, setOffersReceived] = useState([]);
  const [offersReceivedSettings, setOffersReceivedSettings] = useState([]);

  const getOffer = async (id) => {
    setLoading(true);
    return await authApi.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setOffer(response.data.data)
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOfferMyProduct = async (id) => {
    setLoading(true);
    await authApi.get(`${baseUrlMyOffer}/get/${id}`)
      .then((response) => {
        setOffer(response.data.data)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getOfferMyProducts = async () => {
    setLoading(true);
    await authApi.get(`${baseUrlMyOffer}/all`)
      .then((response) => {
        setOffers(response.data.data)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllOfferMade = async () => {
    setLoading(true);
    await authApi.get(`${baseUrl}/all/made`)
      .then((response) => {
        setOffersMadeSettings(response.data)
        setOffersMade(response.data.data)
        setLoading(false)
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllOfferReceived = async () => {
    setLoading(true);
    await authApi.get(`${baseUrl}/all/received`)
      .then((response) => {
        setOffersReceivedSettings(response.data)
        setOffersReceived(response.data.data)
        setLoading(false)
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const create = async (formData) => {
    setLoading(true);
      return await authApi.post(`${baseUrl}/create`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Proposta enviada com sucesso.")
        return response.data;
      })
      .catch((err) => {
        setLoading(false);
        const errosList = err.error.map((er) => {
          if(er.message) {
            return `\n ${er.message}`
          } else {
            return `\n ${er}`
          }
        })
        notifyMessageError('', errosList)
        window.location.reload();
        return false;
      });

  };

  const update = async (id, formData) => {
    setLoading(true);
      await authApi.post(`${baseUrl}/update/${id}`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Atualização realizado com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  const accept = async (id) => {
    setLoading(true);
      await authApi.post(`${baseUrl}/accept`, {
        offer_id: id
      })
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Atualização realizado com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  const reject = async (id) => {
    setLoading(true);
    await authApi.post(`${baseUrl}/reject`, {
      offer_id: id
    })
    .then((response) => {
      setLoading(false)
      notifyMessageSave("Atualização realizado com sucesso.")
      return true;
    })
    .catch((err) => {
      setLoading(false);
      const erros = Object.values(err[1]);
      const errosList = erros.map((er) => {
        return `\n ${er}`
      })
      notifyMessageError(err[0], errosList)
      return false;
    });
  };

  const softDelete = async (id) => {
    setLoading(true);
      await authApi.delete(`${baseUrl}/delete/${id}`)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Exclusão realizada com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  return (
    <OfferContext.Provider
      value={{
        offer,
        offers,
        loading,
        accept,
        reject,
        offersMade,
        offersMadeSettings,
        offersReceived,
        offersReceivedSettings,
        getAllOfferMade,
        getAllOfferReceived,
        getOfferMyProducts,
        getOfferMyProduct,
        setOffer,
        create,
        update,
        softDelete,
        getOffer
      }}
    >
      { children }
    </OfferContext.Provider>

  );
};

export default OfferProvider;
