import { useContext } from 'react'
import {
  React,
  useState,
  createContext,
} from 'react'
import authApi from 'src/services/authApi'
import { SiteContext } from './SiteContext'

export const OfferMessageContext = createContext()

const OfferMessageProvider = (prop) => {
  const baseUrl = 'client/offerMessage'
  const { children } = prop
  const { loading, setLoading, notifyMessageSave, notifyMessageError } = useContext(SiteContext)
  const [offerMessages, setOfferMessages] = useState([])

  const getAllByOfferId = async (id) => {
    await authApi.get(`${baseUrl}/offer/${id}`)
      .then((response) => {
        setOfferMessages(response.data.data)
        return true
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  const sendMessage = async (offer_id, message) => {
    setLoading(true);
      return await authApi.post(`${baseUrl}/create`, {
        offer_id,
        message
      })
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Mensagem enviada com sucesso.")
        getAllByOfferId(offer_id)
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };


  return (
    <OfferMessageContext.Provider
      value={{
        loading,
        offerMessages,
        sendMessage,
        getAllByOfferId,
      }}
    >
      { children }
    </OfferMessageContext.Provider>

  )
}

export default OfferMessageProvider
