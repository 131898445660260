import axios from 'axios';
import Cookies from 'js-cookie';

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossDomain: true
});

function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName];
}

/* eslint no-param-reassign: 2 */
authApi.interceptors.request.use((config) => {
  const token = getCookie('trocaPetToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

authApi.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove('trocaPetToken')
      Cookies.remove('trocaPetUser')
      const url = window.location.href.split('/');
      if (url[3] !== 'login' && url[3] !== '') {
        window.location.href = '/';
      }
    }

    let listMessage = [];

    if (error.response.data) {
      const erros = Object.values(error.response.data);
      erros.forEach((element, index) => {
        if ((element !== 'undefined' && index > 0) || element.message !== '') {
          listMessage.push(element);
        }
      });
    } else if (error.response.data.message) {
      listMessage = [error.response.data.message];
    } else {
      listMessage = ['Erro ao conectar ao servidor, tente novamente mais tarde'];
    }
    return Promise.reject({status: error.response.status, error:listMessage});
  });

export default authApi;
