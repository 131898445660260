/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import { useEffect } from 'react'
import {
  React,
  useState,
  createContext,
} from 'react'
import { useCookies } from "react-cookie"
import authApi from 'src/services/authApi'
import { SiteContext } from './SiteContext'

export const OfferNotificationContext = createContext()

const OfferNotificationProvider = (prop) => {
  const baseUrl = 'client/offerNotification'
  const { children } = prop
  const [cookies, setCookie] = useCookies(["trocaPetToken", "trocaPetUser"])
  const { loading, setLoading } = useContext(SiteContext)
  const [offerNotifications, setOfferNotifications] = useState([])
  const [offerNotificationsSettings, setOfferNotificationsSettings] = useState(null)

  const getAllOfferNotifications = async () => {
    await authApi.get(`${baseUrl}/all`)
      .then((response) => {
        setOfferNotificationsSettings(response.data)
        setOfferNotifications(response.data.data)
        return true
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  const setReadAllOfferNotifications = async (id) => {
    setLoading(true)
    await authApi.get(`${baseUrl}/setAllRead/${id}`)
      .then((response) => {
        getAllOfferNotifications()
        setLoading(false)
        return true
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        return false
      })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const token =  cookies.trocaPetToken && cookies.trocaPetToken !== 'undefined'  ? cookies.trocaPetToken : null
      if(token) {
        getAllOfferNotifications()
      } else {
        clearInterval(interval);
      }
    }, 9000);
    return () => clearInterval(interval);
  }, []);

  return (
    <OfferNotificationContext.Provider
      value={{
        offerNotifications,
        offerNotificationsSettings,
        loading,
        getAllOfferNotifications,
        setReadAllOfferNotifications,
      }}
    >
      { children }
    </OfferNotificationContext.Provider>

  )
}

export default OfferNotificationProvider
