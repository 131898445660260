/* eslint-disable jsx-a11y/alt-text */
import { Component } from 'react';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
export default class SimpleSlider extends Component {
  render() {
    return (
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
      >
        <div>
          <img src='assets/images/banner/1.jpg' />
        </div>
        <div>
          <img src='assets/images/banner/2.jpg' />
        </div>
        <div>
          <img src='assets/images/banner/3.jpg' />
        </div>
      </Carousel>
    );
  }
}