/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
function Footer() {
    return(
        <>
        {/* <!-- >>>>>>>>>>>>>>>>>>> Footer part start >>>>>>>>>>>>>>>>>>>--> */}
        <footer className="bg3 p-t-75 p-b-32">
        <div className="footer-logo p-b-50 text-center">
            <a href="index.html"><img src="images/icons/logo-02.png" alt=""/></a>
        </div>
        <div className="footer-mid">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-12 col-lg-3 p-b-50">
                <div className="foot-last ">
                    <h4 className="stext-301 cl0 p-b-30">Contatos</h4>
                    <a  className="d-block stext-107 cl7 hov-cl1 trans-04" href="callto:5515997078651"><i className="fa-solid fa-phone"></i> +55 (15) 99707.8651</a>
                    <a className="d-block stext-107 cl7 hov-cl1 trans-04" href="mailto:contato@trocapet.com.br"><i className="fa-solid fa-envelope"></i>contato@trocapet.com.br</a>
                </div>
              </div>
              <div className="col-sm-6 col-12 col-lg-3 p-b-50">
                <div className="foot-last ">
                    <h4 className="stext-301 cl0 p-b-30">Social</h4>
                    <ul className="d-flex">
                    <li><a className="fs-18 cl7 hov-cl1 trans-04 m-r-16" href="#"><img src="https://img.icons8.com/color/48/null/facebook-new.png"/></a></li>
                    <li><a className="fs-18 cl7 hov-cl1 trans-04 m-r-16" href="#"><img src="https://img.icons8.com/color/48/null/instagram-new--v1.png"/></a></li>
                    <li><a className="fs-18 cl7 hov-cl1 trans-04 m-r-16" href="#"><img src="https://img.icons8.com/color/48/null/whatsapp--v1.png"/></a></li>
                    </ul>
                </div>
              </div>
            </div>
            <div className="p-t-40">
              <p className="stext-107 cl6 txt-center">
                  Copyright &copy; Todos os direitos reservados <a
                  href="https://www.flexpix.com.br">Flexpix</a>
              </p>
            </div>
          </div>
        </div>
        </footer>
        {/* <!-- >>>>>>>>>>>>>>>>>>> Footer part end >>>>>>>>>>>>>>>>>>>--> */}
        </>
    )

}

export default Footer;