/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useContext } from "react"
import { useOutletContext } from "react-router-dom"
import ProductShop from "src/components/templete/ProductShop"
import { ProductFavoriteContext } from "src/context/ProductFavoriteContext"

function Favorite () {
  const { openModalProduct } = useOutletContext()
  const { getAllProductFavorites, products } = useContext(ProductFavoriteContext)

  useEffect(() => {
    const loadData = async () => {
     await getAllProductFavorites();
    };

    loadData()
  }, [])
  return (
    <>
      <title>{process.env.REACT_APP_NAME} - Favoritos</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Produtos Favoritos
        </h4>
        <div className="row isotope-grid">
          {products.map((product) => (
            <ProductShop showModal={openModalProduct} product={product.product} />
          ))}
        </div>
      </div>
    </>
  )
}

export default Favorite