/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react"
import { useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import PlaceHolderStores from "src/components/placeholders/PlaceHolderStores"
import BranchFilter from "src/components/templete/BranchFilter"
import StoreList from "src/components/templete/StoreList"
import { BranchContext } from "src/context/BranchContext"
import { CityContext } from "src/context/CityContext"

function Stores()  {
  const { openModalProduct } = useOutletContext()
  const { loading, branchs, getAllBranch } = useContext(BranchContext)
  const { cities, getAllCity } = useContext(CityContext)
  useEffect(() => {
    getAllBranch()
    getAllCity()
  }, [])
  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Lojas</title>
    <section className="bg0 p-t-23 p-b-140">
      <div className="list-products" style={{minHeight: '50vh'}}>
        <div className="container">
          <div className="p-b-10">
            <h3 data-test="store-title" className="ltext-103 cl5">
              Lojas
            </h3>
            </div>
              <BranchFilter branch={branchs} cities={cities}/>
              {loading ?
                <PlaceHolderStores />
              :
              <>
              <StoreList
                loading={loading}
                branchs={branchs}
                openModalProduct={openModalProduct}
              />
            </>
            }
          </div>
        </div>
    </section>
    </>
  );
}
export default Stores;