/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import {
  React,
  useState,
  createContext,
} from 'react';
import api from 'src/services/api'
import { SiteContext } from './SiteContext'
import { useCookies } from "react-cookie"

export const ClientContext = createContext();

const ClientProvider = (prop) => {
  const [cookies, setCookie] = useCookies(["trocaPetToken", "trocaPetUser"])
  const { loading, setLoading, notifyMessageSave, notifyMessageWarning, notifyMessageError } = useContext(SiteContext)
  const baseUrl = '/client'
  const { children } = prop
  const [client, setClient] = useState(null);
  const token = cookies.trocaPetToken
  const authHeader = { headers:{ "Authorization" : `Bearer ${token}`}}

  const login = async (email, password) => {
    setLoading(true);
      return await api.post(`${baseUrl}/login`,{email, password})
      .then(async (response) => {
        if(response.status !== 404) {
          setCookie("trocaPetToken",response.data.token);
          setCookie("trocaPetUser",response.data.user);
          setClient(response.data);
          setLoading(false);
          window.location.href = '/';
          return true;
        } else {
          setLoading(false);
          notifyMessageWarning('Usuário não encontrado')
          return false;
        }
      })
      .catch((err) => {
        setLoading(false);
        if(err[0] === 'Client not active') {
          notifyMessageWarning('Usuário não ativo, por favor confira seu e-mail')
        } else {
          notifyMessageWarning('Usuário não encontrado')
        }
        return false;
      });

  };

  const register = async (name, email, phone, password) => {
    setLoading(true);
      return await api.post(`${baseUrl}/create`,{name, email, phone, password})
      .then((response) => {
        setLoading(false)
        notifyMessageSave('Cadastro realizado com sucesso, por favor confira seu e-mail para ativar sua conta')
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  const getClient = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getClientByToken = async () => {
    if(token) {
      setLoading(true);
      return await api.get(`${baseUrl}/getByToken`, authHeader)
      .then((response) => {
        setClient(response.data.data)
        setLoading(false)
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        return false;
      });
    }
  };

  const update = async (id, formData) => {
    setLoading(true);
      await api.post(`${baseUrl}/update/${id}`, formData, authHeader)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Atualização realizado com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  const clientActivate = async (id) => {
    setLoading(true);
    return await api.get(`client/activate/${id}`)
    .then((response) => {
      setLoading(false)
      if(response.data === 1) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      setLoading(false);
      return false;
    });
  };

  return (
    <ClientContext.Provider
      value={{
        client,
        loading,
        register,
        clientActivate,
        setClient,
        update,
        getClientByToken,
        getClient,
        login
      }}
    >
      { children }
    </ClientContext.Provider>

  );
};

export default ClientProvider;
