import { useContext, useState } from "react";
import { SiteContext } from "src/context/SiteContext";

/* eslint-disable jsx-a11y/anchor-is-valid */
function Contacts()  {
  const {sendContact} = useContext(SiteContext)
  const [input, setInput] = useState({
    mail: "",
    message: ""
  })

  const sendMail = async () => {
    const {mail, message} = input
    const response = await sendContact(
      mail,
      message
    )

    if(response) {
      setInput({
        mail: "",
        message: ""
      })
    }
  }

  const onChangeHandler = event => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  return (
    <>
      <title>{process.env.REACT_APP_NAME} - Contacts</title>
      {/* <!-- >>>>>>>>>>>>>>>>>>> Content part start >>>>>>>>>>>>>>>>>>>--> */}
      <section className="bg0 p-t-104 p-b-116">
        <div className="container">
          <h3 data-test="contact-title" className="ltext-103 cl5" style={{ marginBottom: '10px'}}>
            Fale com nós
          </h3>
          <div className="flex-w flex-tr">
            <div className="size-210 bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md">
              <form>
                <h4 className="mtext-105 cl2 txt-center p-b-30">
                  Enviar Menssagem
                </h4>

                <div className="bor8 m-b-20 how-pos4-parent">
                  <input data-test="input-email" onChange={onChangeHandler} className="stext-111 cl2 plh3 size-116 p-l-62 p-r-30" type="text" value={input.mail} name="mail" placeholder="Seu E-mail"/>
                  <img className="how-pos4 pointer-none" src="assets/images/icons/icon-email.png" alt="ICON"/>
                </div>

                <div className="bor8 m-b-30">
                  <textarea data-test="input-text" onChange={onChangeHandler} className="stext-111 cl2 plh3 size-120 p-lr-28 p-tb-25" value={input.message} name="message" placeholder="Como podemos ajudar ?">{input.message}</textarea>
                </div>
                <a href="#!" data-test="button-send" onClick={sendMail} className="formSubmit flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
                  Enviar
                </a>
              </form>
            </div>

            <div className="size-210 bor10 flex-w flex-col-m p-lr-93 p-tb-30 p-lr-15-lg w-full-md">
              <div className="flex-w w-full p-b-42" style={{ display: 'none'}}>
                <span className="fs-18 cl5 txt-center size-211">
                  <span className="lnr lnr-map-marker"></span>
                </span>

                <div className="size-212 p-t-2">
                  <span className="mtext-110 cl2">
                    Endereço
                  </span>

                  <p className="stext-115 cl6 size-213 p-t-18">
                    Lolitamart Center 8th floor, 379 Hudson St, New York, NY 10018 US
                  </p>
                </div>
              </div>

              <div className="flex-w w-full p-b-42">
                <span className="fs-18 cl5 txt-center size-211">
                  <span className="lnr lnr-phone-handset"></span>
                </span>

                <div className="size-212 p-t-2">
                  <span className="mtext-110 cl2">
                    Telefone
                  </span>

                  <p className="stext-115 cl1 size-213 p-t-18">
                    (15) 99707.8651
                  </p>
                </div>
              </div>

              <div className="flex-w w-full">
                <span className="fs-18 cl5 txt-center size-211">
                  <span className="lnr lnr-envelope"></span>
                </span>

                <div className="size-212 p-t-2">
                  <span className="mtext-110 cl2">
                    E-mail
                  </span>

                  <p className="stext-115 cl1 size-213 p-t-18">
                    <a href="mailto:contato@trocapet.com.br">contato@trocapet.com.br</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- >>>>>>>>>>>>>>>>>>> Content part end >>>>>>>>>>>>>>>>>>>--> */}
    </>
  );
}
export default Contacts;