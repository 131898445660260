/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Form from 'react-bootstrap/Form'
import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { ClientContext } from 'src/context/ClientContext'
import { BranchContext } from 'src/context/BranchContext'
import { SiteContext } from 'src/context/SiteContext'
import InputMask from 'react-input-mask'

function MyStore () {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getBranchAuth, branch, create, update } = useContext(BranchContext)
  const { getAddressByZipCode } = useContext(SiteContext)
  const { getClientByToken, client } = useContext(ClientContext)
  const [inputValues, setInputValues] = useState({
    fantasy_name: "",
    social_name: "",
    document_number: "",
    zip_code: "",
    state: "",
    city: "",
    district: "",
    street: "",
    number: "",
    complement: "",
  });

  const [error, setError] = useState({
    fantasy_name: "",
    social_name: "",
    document_number: "",
    zip_code: "",
    state: "",
    city: "",
    district: "",
    street: "",
    number: "",
    complement: "",
  });

  const [image, setImage] = useState(null);

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    setError({...error,
      [event.target.name]: ''
    })
  };

  const handleChangeFile = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setInputValues({
      ...inputValues,
      logo: event.target.files[0]
    })
  };

  useEffect(()=>{
    if(id) {
      getBranchAuth(id)
    }
    getClientByToken()
  }, [id])

  useEffect(() => {
    if(branch) {
      setInputValues({
        fantasy_name: branch.fantasy_name,
        social_name: branch.social_name,
        document_number: branch.document_number,
        zip_code: branch.zip_code,
        state: branch.state,
        city: branch.city,
        district: branch.district,
        street: branch.street,
        number: branch.number,
        complement: branch.complement,
      })
      setImage(branch.logo)
    }
  },[branch])

  function validateInput (fantasy_name, zip_code, state, city, district, street) {
    if(fantasy_name === '') {
      setError({
        ...error,
        fantasy_name: 'O Nome Fantasia não pode ser nulo'
      })
      return false;
    }
    if(zip_code === '') {
      setError({
        ...error,
        zip_code: 'A CEP não pode ser nula'
      })
      return false;
    }

    if(state === '') {
      setError({
        ...error,
        state: 'A Estado não pode ser nula'
      })
      return false;
    }

    if(city === '') {
      setError({
        ...error,
        city: 'A Cidade não pode ser nula'
      })
      return false;
    }

    if(district === '') {
      setError({
        ...error,
        district: 'O Bairro não pode ser nula'
      })
      return false;
    }

    if(street === '') {
      setError({
        ...error,
        street: 'A Rua não pode ser nula'
      })
      return false;
    }

    return true
  }

  function resetErrors () {
    setError({
      name: null,
      brand_id: null,
      value: null,
      quantity: null,
      validity: null
    })
  }

  const save = async () => {
    const formData = new FormData();
    const {fantasy_name, social_name, document_number, zip_code, state, city, district, street, number, complement, logo} = inputValues
    resetErrors()
    const valid = validateInput(fantasy_name, zip_code, state, city, district, street)
    if(valid) {
      formData.append("fantasy_name", fantasy_name);
      formData.append("social_name", social_name);
      formData.append("document_number", document_number);
      formData.append("zip_code", zip_code);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("district", district);
      formData.append("street", street);
      formData.append("number", number);
      formData.append("complement", complement);
      formData.append("client_id", client.id);
      if(logo) {
        formData.append("logo", logo);
      }

      if(id) {
        await update(id, formData)
        navigate(`/my-stores`)
      } else {
        await create(formData)
        navigate(`/my-stores`)
      }
    }
  }

  const zipCodeChange = async (e) => {
    let zipCode = e.target.value.replace('-','')
    zipCode = zipCode.trim()
    if(zipCode.length === 8) {
      const address = await getAddressByZipCode(zipCode)
      if(address) {
        setInputValues({
          ...inputValues,
          zip_code: address.data.cep,
          state: address.data.uf,
          city: address.data.localidade,
          district: address.data.bairro,
          street: address.data.logradouro
        })
      }

    }
  }

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Minha Loja</title>
     <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
      <h1>{inputValues.fantasy_name}</h1>
          <div className="input-form image">
            <div style={{ clear: 'both'}}></div>
            <div className="how-pos4-parent">
              {image !== '' ?
              <img src={image} />
              :
              <img src={`${process.env.REACT_APP_APP_URL}assets/images/no-image.png`} />
              }
            </div>
          </div>

          <Form.Group className="mb-3">
            <Form.Label>Imagem</Form.Label>
            <Form.Control onChange={handleChangeFile} type="file" name="logo" placeholder="Selecione a imagem" />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Nome Fantasia</Form.Label>
            <Form.Control data-test="input-fantasy-name" onChange={onChangeHandler} type="text" value={inputValues.fantasy_name} name="fantasy_name" placeholder="Nome Fantasia" />
            {error.fantasy_name && <span className='err'>{error.fantasy_name}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control data-test="input-social-name" onChange={onChangeHandler} type="text" value={inputValues.social_name} name="social_name" placeholder="Razão Social" />
            {error.social_name && <span className='err'>{error.social_name}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>CNPJ / CPF</Form.Label>
            <Form.Control data-test="input-document" onChange={onChangeHandler} type="text" value={inputValues.document_number} name="document_number" placeholder="CNPJ/CPF" />
            {error.document_number && <span className='err'>{error.document_number}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>CEP</Form.Label>
            <InputMask
              className="form-control"
              data-test="input-cep"
              mask='99999-999'
              value={inputValues.zip_code}
              name="zip_code"
              maskChar=" "
              placeholder='CEP'
              onChange={(e) => {onChangeHandler(e); zipCodeChange(e)}}>
            </InputMask>
            {error.zip_code && <span className='err'>{error.zip_code}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Estado</Form.Label>
            <Form.Control data-test="input-state" onChange={onChangeHandler} type="text" value={inputValues.state} name="state" placeholder="Estado" />
            {error.state && <span className='err'>{error.state}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cidade</Form.Label>
            <Form.Control  data-test="input-city" onChange={onChangeHandler} type="text" value={inputValues.city} name="city" placeholder="Cidade" />
            {error.city && <span className='err'>{error.city}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Bairro</Form.Label>
            <Form.Control data-test="input-district" onChange={onChangeHandler} type="text" value={inputValues.district} name="district" placeholder="Bairro" />
            {error.district && <span className='err'>{error.district}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Rua</Form.Label>
            <Form.Control data-test="input-street" onChange={onChangeHandler} type="text" value={inputValues.street} name="street" placeholder="Rua" />
            {error.street && <span className='err'>{error.street}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Numero</Form.Label>
            <Form.Control data-test="input-number" onChange={onChangeHandler} type="text" value={inputValues.number} name="number" placeholder="Numero" />
            {error.number && <span className='err'>{error.number}</span>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Complemento</Form.Label>
            <Form.Control data-test="input-complement" onChange={onChangeHandler} type="text" value={inputValues.complement} name="complement" placeholder="Complemento" />
            {error.complement && <span className='err'>{error.complement}</span>}
          </Form.Group>
          <a href={undefined} data-test="btn-save" onClick={save} className="formSubmit flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
            Salvar
          </a>
      </div>
    </>
  )
}

export default MyStore