/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "src/context/SiteContext";
function ChangePassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { notifyMessageWarning } = useContext(SiteContext)
  const { changePassword, getTokenRecovery } = useContext(SiteContext)
  const [inputValues, setInputValues] = useState({
    password: '',
    repeat: ''
  });

  useEffect(() => {
    (async()=> {
      if(token) {
       const response = await getTokenRecovery(token)
       if(!response) {
        navigate(`/`)
       }
      }
    })();
  },[])

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const recovery = async () => {
    const valid = validateInput(inputValues.password, inputValues.repeat)
    if(valid) {
      const response = await changePassword(inputValues.password, token)
      setInputValues({
        password:'',
        repeat: ''
      })

      if(response) {
        navigate('/')
      }
    }
  }

  function validateInput (password, repeat) {

    if(password === '') {
      notifyMessageWarning('O Senha não pode ser nulo')
      return false;
    }

    if(repeat === '') {
      notifyMessageWarning('A confirmação não pode ser nula')
      return false;
    }

    if(repeat !== password) {
      notifyMessageWarning('A confirmação da senha tem que ser a mesma que a senha')
      return false;
    }

    return true
  }


  return (
    <>
      <title>{process.env.REACT_APP_NAME} - Alteração de Senha</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '800px', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Alterar senha
        </h4>

        <div className="input-form">
          <label>Senha</label>
          <div className="bor8 how-pos4-parent">
            <input value={inputValues.password} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="password" placeholder="Sua Senha"/>
          </div>
        </div>

        <div className="input-form">
          <label>Repetir a Senha</label>
          <div className="bor8 how-pos4-parent">
            <input value={inputValues.repeat} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="repeat" placeholder="Repita sua Senha"/>
          </div>
        </div>
        <button href={undefined} onClick={recovery} className="formSubmit flex-c-m stext-101 cl0 size-121 bg1 bor1 p-lr-15 trans-04 pointer">
          Alterar Senha
        </button>
      </div>
    </>
  );
}

export default ChangePassword;