/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import { Spinner } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { ClientContext } from "src/context/ClientContext"

function ActivateClient () {
  const { id } = useParams()
  const { clientActivate } = useContext(ClientContext)
  const [active, setActive] = useState('')

  useEffect(()=> {
    async function loadData () {
      const response = await clientActivate(id)
      setActive(response)
    }
    loadData()
  },[id])

  const validate = () => {
    if(active === '') {
      return (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      )
    }

    if(active) {
      return(
        <p data-test="activate-message">Usuário ativado com sucesso, <br/> acesse seu aplicativo com seu usuário e senha.</p>
      )
    }

    if(!active) {
      return(
        <p data-test="activate-message">Usuário não encontrado</p>
      )
    }
  }

  return (<>
    <title>{process.env.REACT_APP_NAME} -  Ativação de Usuário</title>
    <div className="activate">
      <div className="box">
        <div className="item">
            <div className="logo">
              <img width={400} src={`${process.env.REACT_APP_APP_URL}assets/images/logo/logo.png`} alt="IMG-LOGO"/>
            </div>
            <div>
              {validate()}
            </div>
        </div>
      </div>
    </div>
  </>)
}

export default ActivateClient