/* eslint-disable jsx-a11y/alt-text */
function BathGroom() {
  return (
    <>
    <title>{process.env.REACT_APP_NAME} - A importância do banho e tosa</title>
    <section className="bg0 p-t-23 p-b-140">
      <div className="publicity" style={{minHeight: '50vh', marginTop: '50px'}}>
        <div className="container">
          <div className="p-b-10">
            <h1 className="ltext-103 cl5">
              A importância do banho e tosa
            </h1>

            <div style={{ marginTop:'50px'}}>
              <p className="stext-113">Enquanto para muitas pessoas o conceito de cuidar do seu animal de estima&ccedil;&atilde;o evoca no&ccedil;&otilde;es de pinc&eacute;is e la&ccedil;os, &eacute; de fato um elemento vital para sua sa&uacute;de e bem-estar. A prepara&ccedil;&atilde;o regular do seu animal permite que voc&ecirc; pegue qualquer doen&ccedil;a ou condi&ccedil;&atilde;o subjacente mais cedo, o que significa que eles poder&atilde;o ser tratados com mais rapidez e efici&ecirc;ncia e, portanto, ter&atilde;o menos probabilidade de ter um efeito duradouro no seu animal de estima&ccedil;&atilde;o.</p>
              <br/>
              <div style={{ display:'flex'}}>
                <div style={{ flex: '2', textAlign:'justify'}}>
                  <p className="stext-113">No entanto, nem todos os animais desfrutam do processo de limpeza e muitos propriet&aacute;rios acham que &eacute; mais f&aacute;cil enviar seu animal de estima&ccedil;&atilde;o para uma tosa profissional regularmente. H&aacute; aqueles tutores que at&eacute; optam por um curso de banho e tosa para cuidar dos seus pets. Se voc&ecirc; tem um filhote de cachorro ou um gatinho, trein&aacute;-los para garantir que o processo de limpeza seja uma parte importante do aprendizado e ser&aacute; ben&eacute;fico para eles quando atingirem a maturidade. Isso &eacute; especialmente verdadeiro no caso de unhas cortadas e limpeza de orelhas, que exigem que elas fiquem completamente paradas para o processo.&nbsp;</p>
                  <br/>
                  <p className="stext-113">Bons tutores geralmente come&ccedil;am a banhar suas ninhadas assim que tiverem idade suficiente para ajud&aacute;-los a se acostumar com o processo. Mesmo que voc&ecirc; opte por usar um profissional para cuidar de animais de estima&ccedil;&atilde;o, ainda existem v&aacute;rias t&eacute;cnicas de cuidados regulares que voc&ecirc; pode fazer em casa com seu animal de estima&ccedil;&atilde;o para fortalecer seu v&iacute;nculo.</p>
                </div>
                <div style={{ flex: '1', textAlign:'right'}}>
                  <img style={{height:'300px'}} className='img-fluid shadow-4' src={`${process.env.REACT_APP_APP_URL}/assets/images/publicity/banho.jpg`} />
                </div>
              </div>
              <br/>
              <br/>
              <h2><strong>Benef&iacute;cios da tosa e do banho</strong></h2>
              <br/>
              <p className="stext-113">Aqui est&atilde;o alguns dos importantes benef&iacute;cios da prepara&ccedil;&atilde;o para animais de estima&ccedil;&atilde;o.</p>
              <br/>
              <h3>Escova&ccedil;&atilde;o</h3>
              <br/>
              <p className="stext-113">Muitas vezes, mal interpretado como a &uacute;nica parte da limpeza, a escova&ccedil;&atilde;o ainda &eacute; um grande elemento do processo. Escovar regularmente remove a sujeira, caspa e pelos mortos do seu animal de estima&ccedil;&atilde;o e em gatinhos e gatos tamb&eacute;m pode reduzir o n&uacute;mero de bolas de pelos que eles ingerem. Tamb&eacute;m evita emaranhados e esteiras que podem levar a dores e infec&ccedil;&otilde;es. A escova&ccedil;&atilde;o tamb&eacute;m estimula os &oacute;leos naturais do pelo de seus animais de estima&ccedil;&atilde;o, que s&atilde;o espalhados pelo pelo, deixando-o com um brilho brilhante e saud&aacute;vel.</p>
              <p className="stext-113">Ao escovar seu animal de estima&ccedil;&atilde;o, voc&ecirc; poder&aacute; fazer um exame completo de sua pele, identificando problemas como carrapatos, pulgas, manchas calvas e secas. Qualquer incha&ccedil;o ou outra anormalidade tamb&eacute;m ser&aacute; mais f&aacute;cil de sentir.</p>
              <br/>
              <h3>Orelhas</h3>
              <br/>
              <p className="stext-113">As orelhas podem ser uma preocupa&ccedil;&atilde;o para v&aacute;rias ra&ccedil;as diferentes que s&atilde;o mais suscet&iacute;veis a infec&ccedil;&otilde;es e parasitas. Eles devem estar limpos e sem odor. Qualquer coisa que pare&ccedil;a vermelha, inchada ou com um cheiro desagrad&aacute;vel, al&eacute;m de qualquer sinal de infesta&ccedil;&atilde;o por &aacute;caros ou carrapatos, deve ser examinada por um veterin&aacute;rio o mais r&aacute;pido poss&iacute;vel.</p>
              <br/>
              <h3>Olhos</h3>
              <br/>
              <p className="stext-113">Os olhos tamb&eacute;m podem ser propensos &agrave; infec&ccedil;&atilde;o se os cabelos ao redor deles n&atilde;o forem mantidos aparados. Eles devem ser claros e claros. Os olhos lacrimejantes ou qualquer coisa que pare&ccedil;a dolorida tamb&eacute;m devem ser verificados pelo seu veterin&aacute;rio.</p>
              <br/>
              <h3>Dentes</h3>
              <br/>
              <p className="stext-113">Escovar os dentes regularmente &eacute; a &uacute;nica maneira de ajudar a combater a doen&ccedil;a periodontal, e se o seu animal tiver mau h&aacute;lito, &eacute; um sinal claro de um problema dent&aacute;rio. Seu veterin&aacute;rio ter&aacute; prazer em recomendar escovas de dentes e creme dental especializados para animais de estima&ccedil;&atilde;o &ndash; basta perguntar!</p>
              <br/>
              <h3>Tomando banho</h3>
              <br/>
              <p className="stext-113">Banhar o animal com muita frequ&ecirc;ncia pode realmente ter um efeito prejudicial &agrave; sa&uacute;de, pois retira todos os &oacute;leos naturais da pele, deixando-os com manchas secas e com coceira que, quando arranhadas, podem causar infec&ccedil;&atilde;o. Dito isto, &eacute; necess&aacute;ria alguma lavagem para garantir que seu animal permane&ccedil;a limpo e livre de germes. Pergunte ao seu veterin&aacute;rio que tipo de hor&aacute;rio de banho eles recomendariam para sua ra&ccedil;a de cachorro.</p>
              <br/>
              <h3>Aparar unhas</h3>
              <br/>
              <p className="stext-113">Os animais de estima&ccedil;&atilde;o podem ter unhas extremamente afiadas que, se n&atilde;o forem aparadas com frequ&ecirc;ncia, podem causar ferimentos graves. Guarni&ccedil;&otilde;es regulares podem reduzir o risco de ferimentos, impedindo-os de serem presos em carpetes ou outros estofados e impedindo a probabilidade de unhas crescerem. Para animais de estima&ccedil;&atilde;o mais velhos, tamb&eacute;m pode aliviar a artrite e outras dores nas articula&ccedil;&otilde;es.</p>
              <p><br/></p>
              <p><br/></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default BathGroom;