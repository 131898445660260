import {
  React,
  useState,
  createContext,
} from 'react';
import api from 'src/services/api';

export const CategoryContext = createContext();

const CategoryProvider = (prop) => {
  const { children } = prop;
  const baseUrl = '/category';
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesSettings, setCategorieSettings] = useState(null);

  const getAllCategory = async () => {
    await api.get(`${baseUrl}/all`)
      .then((response) => {
        setCategorieSettings(response.data);
        setCategories(response.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const getAllCategoryByBranch = async (id) => {
    return await api.get(`${baseUrl}/branch/${id}`)
      .then((response) => {
        setCategorieSettings(response.data);
        setCategories(response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const getCategory = async (id) => {
    await api.get(`${baseUrl}/${id}`)
      .then((response) => {
        setCategory(response.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return (
    <CategoryContext.Provider
      value={{
        category,
        categoriesSettings,
        categories,
        getAllCategoryByBranch,
        setCategory,
        getAllCategory,
        getCategory,
      }}
    >
      { children }
    </CategoryContext.Provider>

  );
};

export default CategoryProvider;
