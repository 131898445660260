/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";
import NoImage from "src/components/templete/NoImage";
import ProductList from "src/components/templete/ProductList";
import { BranchContext } from "src/context/BranchContext";
import { CategoryContext } from "src/context/CategorieContext";
import { ProductContext } from "src/context/ProductContext";

function Store() {
  const { id } = useParams()
  const{getBranch, branch} = useContext(BranchContext)
  const { openModalProduct } = useOutletContext()
  const{getAllProductsByBranchNotAuth, branchProducts} = useContext(ProductContext)
  const{getAllCategoryByBranch, categories} = useContext(CategoryContext)
  const[currentBranch, setCurrentBranch] = useState({
    fantasy_name: ''
  })
  const[currentProducts, setCurrentProducts] = useState()
  const[currentCategories, setCurrentCategories] = useState()

  useEffect(() => {
    (async() => {
      await getBranch(id)
      await getAllCategoryByBranch(id)
      await getAllProductsByBranchNotAuth(id)
    })()
  },[id])

  useEffect(() => {
    if(branch) {
      setCurrentBranch(branch)
    }
  },[branch])

  useEffect(() => {
    if(categories) {
      setCurrentCategories(categories)
    }
  },[categories])

  useEffect(() => {
    if(branchProducts) {
      setCurrentProducts(branchProducts)
    }
  },[branchProducts])


  return (
    <>
      <title>{process.env.REACT_APP_NAME} - {currentBranch.fantasy_name}</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md store-page" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <Card style={{ width: '100%', marginBottom:'10px' }}>
          <Card.Body>
            <Card.Title>Dados da Loja</Card.Title>
            <Card.Text>
              <Row>
                <div className="image">
                    {currentBranch.logo && currentBranch.logo !== "" ?
                    <img src={currentBranch.logo}  width="100%"/>
                    :
                    <NoImage />
                    }
                </div>
                <div className="info">
                  <div className="name">Nome : {currentBranch.fantasy_name}</div>
                  <div className="address">Endereço : {currentBranch.city}, {currentBranch.district} - {currentBranch.state}</div>
                </div>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: '100%', marginBottom:'10px' }}>
          <Card.Body>
            <Card.Title>Produtos para Troca</Card.Title>
            <Card.Text>
            <ProductList
                branch={id}
                products={currentProducts}
                categories={currentCategories}
                openModalProduct={openModalProduct}
              />
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default Store;