import {
  React,
  useState,
  createContext,
} from 'react';
import { useCookies } from 'react-cookie';
import api from 'src/services/api';

export const CityContext = createContext();

const CityProvider = (prop) => {
  const [cookies] = useCookies(["trocaPetToken"])
  const { children } = prop;
  const baseUrl = '/branch';
  const [cities, setCities] = useState(null);


  const getAllCity = async () => {
    await api.get(`${baseUrl}/cities`,{
      headers:{
        Authorization: 'Bearer '+cookies.trocaPetToken
      }
    })
      .then((response) => {
        setCities(response.data.data);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return (
    <CityContext.Provider
      value={{
        cities,
        setCities,
        getAllCity,
      }}
    >
      { children }
    </CityContext.Provider>

  );
};

export default CityProvider;
