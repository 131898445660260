import { useContext, useEffect, useState } from "react"
import { BranchContext } from "src/context/BranchContext"

/* eslint-disable jsx-a11y/anchor-is-valid */
function BranchFilter (props) {
  const [searchText, setSearchText] = useState('')
  const [cities, setCities] = useState([])
  const { searchBranchs } = useContext(BranchContext)
  const [city, setCity] = useState("")

  useEffect(() => {
    if(props.cities && props.cities.length > 0) {
      setCities(props.cities)
    } else {
      setCities([])
    }
  },[props.categories, props.cities])

  function handleChange(event) {
    setSearchText(event.target.value)
    search(event.target.value, city)
  }

  function handleChangeCity(event) {
    setCity(event.target.value)
    search(searchText, event.target.value)
  }

  async function search(name, city) {
    await searchBranchs(name, city)
  }

  return (
    <>
      <div className="flex-w flex-sb-m p-b-52">
        <div className={"panel-search w-full p-t-10 p-b-15" }>
        <div style={{ display: "flex"}}>
            <div className="col-md-7">
              <div className="bor8 dis-flex p-l-15">
                <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                  <i className="zmdi zmdi-search"></i>
                </button>

                <input data-test="input-search-branch" value={searchText} onChange={handleChange} className="mtext-107 cl2 size-114 plh2 p-r-15" type="text" name="search-product"
                  placeholder="Pesquisar"/>
              </div>
            </div>

            <div className="col-md-5">
              <div className="dis-flex p-l-15" >
                <select onChange={handleChangeCity} data-test="select-product-search-city" name="search-city" className="bor8 size-114">
                  <option value="">Todas Cidades</option>
                {cities.map((city, index) => (
                  <option key={city.name}>{city.name}</option>
                ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BranchFilter