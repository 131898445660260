/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { SiteContext } from "src/context/SiteContext";
function Recovery() {
  const { passwordRecovery } = useContext(SiteContext)
  const [inputValues, setInputValues] = useState({
    email: '',
  });

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const recovery = async () => {
    await passwordRecovery(inputValues.email)
    setInputValues({
      email:''
    })
  }

  return (
    <>
      <title>{process.env.REACT_APP_NAME} - Recuperar Senha</title>
      <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '800px', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Recuperar Senha
        </h4>

        <div className="input-form">
          <label>E-mail</label>
          <div className="bor8 how-pos4-parent">
            <input value={inputValues.email} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="email" placeholder="Seu E-mail"/>
          </div>
        </div>
        <button href={undefined} onClick={recovery} className="formSubmit flex-c-m stext-101 cl0 size-121 bg1 bor1 p-lr-15 trans-04 pointer">
          Enviar E-mail de Redefinição
        </button>
      </div>
    </>
  );
}

export default Recovery;