import SiteProvider from 'src/context/SiteContext'
import ClientProvider from 'src/context/ClientContext'
import ProductProvider from 'src/context/ProductContext'
import CategoryProvider from 'src/context/CategorieContext'
import MainTemplete from 'src/components/templete/MainTemplete'

import Index from 'src/pages/Index'
import Stores from 'src/pages/Stores'
import About from 'src/pages/About'
import Contact from 'src/pages/Contact'
import MyStore from 'src/pages/MyStore'
import Profile from 'src/pages/Profile'
import Product from 'src/pages/Product'
import Register from 'src/pages/Register'
import Products from 'src/pages/Products'
import Favorite from 'src/pages/Favorite'
import MyStores from 'src/pages/MyStores'
import MyProducts from 'src/pages/MyProducts'
import OfferProvider from 'src/context/OfferContext'
import BranchProvider from 'src/context/BranchContext'
import ProductFavoriteProvider from 'src/context/ProductFavoriteContext'
import OfferNotificationProvider from 'src/context/OfferNotificationContext'
import ActivateClient from 'src/pages/ActivateClient'
import CreateOffer from 'src/pages/CreateOffer'
import ManagerOffer from 'src/pages/ManagerOffer'
import EditOffer from 'src/pages/EditOffer'
import RecreateOffer from 'src/pages/RecreateOffer'
import Store from 'src/pages/Store'
import OfferMessageProvider from './context/OfferMessageContext'
import Recovery from 'src/pages/Recovery'
import ChangePassword from 'src/pages/ChangePassword'
import ListOffers from 'src/pages/ListOffers'
import PetMeket from 'src/pages/PetMaket'
import BathGroom from 'src/pages/BathGroom'
import LoginAs from 'src/pages/LoginAs'
import CityProvider from './context/CityContext'
import CustomTemplete from './components/templete/CustomTemplete'

const routes = [
  {
    path: '/',
    element:
      <CategoryProvider>
        <SiteProvider>
          <ProductProvider>
            <ClientProvider>
              <BranchProvider>
                <ProductFavoriteProvider>
                  <OfferProvider>
                    <OfferNotificationProvider>
                      <OfferMessageProvider>
                        <CityProvider>
                          <MainTemplete />
                        </CityProvider>
                      </OfferMessageProvider>
                    </OfferNotificationProvider>
                  </OfferProvider>
                </ProductFavoriteProvider>
              </BranchProvider>
            </ClientProvider>
          </ProductProvider>
        </SiteProvider>
      </CategoryProvider>,
    children: [
      { path: '', element: <Index /> },
      { path: '/products', element: <Products /> },
      { path: '/stores', element: <Stores /> },
      { path: '/store/:id', element: <Store /> },
      { path: '/about', element: <About /> },
      { path: '/contact', element: <Contact /> },
      { path: '/register', element: <Register /> },
      { path: '/offers', element: <ListOffers /> },
      { path: '/my-store/:id', element: <MyStore /> },
      { path: '/my-store', element: <MyStore /> },
      { path: '/my-stores', element: <MyStores /> },
      { path: '/my-products', element: <MyProducts /> },
      { path: '/profile', element: <Profile /> },
      { path: '/branch/:branch_id/product', element: <Product /> },
      { path: '/product/:id', element: <Product /> },
      { path: '/make/offer/:productId', element: <CreateOffer /> },
      { path: '/offer/:id', element: <ManagerOffer /> },
      { path: '/edit/offer/:id', element: <EditOffer /> },
      { path: '/recreate/offer/:id', element: <RecreateOffer /> },
      { path: '/favorite', element: <Favorite /> },
      { path: '/recovery', element: <Recovery /> },
      { path: '/password/recovery/:token', element: <ChangePassword /> },
      { path: '/pet-market', element: <PetMeket /> },
      { path: '/bath-groom', element: <BathGroom /> },
      { path: '/login-as/:token/:user', element: <LoginAs /> },
    ]
  },
  {
    path: '/',
    element:<SiteProvider>
              <ClientProvider>
                <CustomTemplete />
              </ClientProvider>
            </SiteProvider>,
    children: [
      { path: '/client/activate/:id', element: <ActivateClient /> },
    ]
  },
];

export default routes;
