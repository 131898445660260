import {
  React,
  useState,
  createContext,
  useContext,
} from 'react';
import { useCookies } from 'react-cookie';
import api from 'src/services/api'
import authApi from 'src/services/authApi'
import { SiteContext } from './SiteContext'

export const ProductContext = createContext();

const ProductProvider = (prop) => {
  const [cookies] = useCookies(["trocaPetToken"])
  const { loading, setLoading, notifyMessageSave, notifyMessageError } = useContext(SiteContext)
  const baseUrl = '/product';
  const { children } = prop;
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [myProducts, setMyProducts] = useState([]);
  const [myProductsSettings, setMyProductsSettings] = useState(null);
  const [productsSettings, setProductsSettings] = useState(null);
  const [branchProducts, setBranchProducts] = useState([]);
  const [branchProductsSettings, setBranchProductsSettings] = useState(null);
  const [searchParams, setSearchParams] = useState({
    name: "",
    category_id: 0,
    city: "",
    page: 1
  })
  const token = cookies.trocaPetToken

  const getAllProducts = async () => {
    setLoading(true);
    if(token) {
      await authApi.get(`${baseUrl}/all`)
        .then((response) => {
          setProductsSettings(response.data);
          setProducts(response.data.data);
          setLoading(false);
          return true;
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          return false;
        });
    } else {
    await api.get(`${baseUrl}/all`)
      .then((response) => {
        setProductsSettings(response.data);
        setProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    }
  };

  const getAllProductsByBranch = async (id) => {
    setLoading(true);
    await authApi.get(`client${baseUrl}/getAllByBranch/${id}`)
      .then((response) => {
        setBranchProductsSettings(response.data);
        setBranchProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllProductsByBranchNotAuth = async (id) => {
    setLoading(true);
    return await authApi.get(`${baseUrl}/getAllByBranch/${id}`)
      .then((response) => {
        setBranchProductsSettings(response.data);
        setBranchProducts(response.data.data);
        setLoading(false);
        return response.data.data;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllProductsByClient = async (name, client_id) => {
    setLoading(true);
    await authApi.get(`client${baseUrl}/getAllByClient`, { params: { name:name, client_id: client_id }})
      .then((response) => {
        setMyProductsSettings(response.data.meta);
        setMyProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllProductsByClientAndOffer = async (name, client_id, offer_id) => {
    setLoading(true);
    await authApi.get(`client${baseUrl}/getAllByClient`, { params: { name:name, client_id: client_id, offer_id: offer_id }})
      .then((response) => {
        setMyProductsSettings(response.data.meta);
        setMyProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllProductsByClientAndBranch = async (branch_id, name) => {

    setLoading(true);
    await authApi.get(`client${baseUrl}/getAllByBranchAndName/${branch_id}/${name}`)
      .then((response) => {
        setMyProductsSettings(response.data.meta);
        setMyProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getAllMyProductsByBranch = async (id) => {
    setLoading(true);
    await authApi.get(`client${baseUrl}/getAllByBranch/${id}`)
      .then((response) => {
        setMyProductsSettings(response.data.meta);
        setMyProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginateProductsByBranch = async (page) => {
    setLoading(true);
    await authApi.get(`${page}`)
      .then((response) => {
        setBranchProductsSettings(response.data);
        setBranchProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const paginateMyProducts = async (page) => {
    setLoading(true);
    await authApi.get(`${page}`)
      .then((response) => {
        setMyProductsSettings(response.data.meta);
        setMyProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getMorProducts = async () => {
    setLoading(true);
    const newPage = searchParams.page + 1; // Incrementando o valor de page corretamente
    setSearchParams(prevParams => ({ ...prevParams, page: newPage }));
    if(searchParams.name !== "" || searchParams.category_id !== ""|| searchParams.city !== "") {
      searchProducts(searchParams.name, searchParams.category_id, searchParams.city, newPage);
    } else {

    await api.get(`${productsSettings.links.next}`)
      .then((response) => {
        setProductsSettings(response.data)
        let currentProducts = products
        response.data.data.forEach((product) => {
          currentProducts.push(product)
        })

        setProducts(currentProducts)
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    }
  }

  const searchProducts = async (name, category_id, city, page = 1) => {
    setLoading(true);
    if(name === '' && category_id === 0 && city === '') {
      getAllProducts()
    } else {
      setSearchParams({
        name: name,
        category_id: category_id,
        city: city,
        page: page
      })
      await authApi.post(`${baseUrl}/search?page=${page}`,
        {
          name: name,
          category_id: category_id,
          city: city
        }
      )
      .then((response) => {
        setProductsSettings(response.data);
        if(page > 1) {
          let currentProducts = products
          response.data.data.forEach((product) => {
            currentProducts.push(product)
          })
          setProducts(currentProducts)
        } else {
          setProducts(response.data.data);
        }
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    }
  };

  const searchProductsByBranch = async (branch_id, name, category_id) => {
    setLoading(true);

    if(name === '' && category_id === 0) {
      getAllProductsByBranchNotAuth(branch_id)
    } else {

      await api.post(`${baseUrl}/search`,
        {
          branch_id: branch_id,
          name:name,
          category_id: category_id,
        }
      )
      .then((response) => {
        setBranchProductsSettings(response.data);
        setBranchProducts(response.data.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
    }
  };

  const getProduct = async (id) => {
    setLoading(true);
    await api.get(`${baseUrl}/get/${id}`)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };

  const getProductAuth = async (id) => {
    setLoading(true);
    await authApi.get(`client${baseUrl}/get/${id}`)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
        return true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return false;
      });
  };


  const create = async (formData) => {
    setLoading(true);
      return await authApi.post(`client${baseUrl}/create`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Cadastro realizado com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  const update = async (id, formData) => {
    setLoading(true);
      return await authApi.post(`client${baseUrl}/update/${id}`, formData)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Atualização realizado com sucesso.")
        return true;
      })
      .catch((err) => {
        const erros = Object.values(err.error);
        let errorList = '\n '
        erros.forEach((error) =>
        {
            const er = Object.values(error);
            er.forEach((el) => {
              errorList = errorList.concat(el[0] , ' \n')
            })
        })
        setLoading(false);
        notifyMessageError('Atenção',  errorList)
        return false;
      });

  };

  const softDelete = async (id) => {
    setLoading(true);
      await authApi.delete(`client${baseUrl}/delete/${id}`)
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Exclusão realizada com sucesso.")
        return true;
      })
      .catch((err) => {
        setLoading(false);
        const erros = Object.values(err[1]);
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        notifyMessageError(err[0], errosList)
        return false;
      });

  };

  return (
    <ProductContext.Provider
      value={{
        product,
        products,
        create,
        update,
        softDelete,
        myProducts,
        productsSettings,
        myProductsSettings,
        branchProducts,
        branchProductsSettings,
        searchProductsByBranch,
        getAllProductsByBranchNotAuth,
        getAllProductsByClient,
        getAllProductsByClientAndOffer,
        getAllMyProductsByBranch,
        paginateProductsByBranch,
        paginateMyProducts,
        getAllProductsByClientAndBranch,
        loading,
        getAllProductsByBranch,
        setProduct,
        getAllProducts,
        getMorProducts,
        getProduct,
        searchProducts,
        getProductAuth,
        searchParams,
        setSearchParams
      }}
    >
      { children }
    </ProductContext.Provider>

  );
};

export default ProductProvider;
