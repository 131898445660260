/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { useContext } from "react"
import InputMask from 'react-input-mask'
import { useNavigate } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css'
import { ClientContext } from "src/context/ClientContext"

function Register () {
  const navigate = useNavigate()
  const { register } = useContext(ClientContext)
  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    repeat: ''
  });
  const [error, setError] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    repeat: ''
  });

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    setError({...error,
      [event.target.name]: ''
    })
  };

  function resetErrors () {
    setError({
      name: null,
      email: '',
      phone: '',
      password: '',
      repeat: ''
    })
  }

  function resetForm () {
    setInputValues({
      name: '',
      email: '',
      phone: '',
      password: '',
      repeat: ''
    })
  }

  function validateInput (name, email, phone, password, repeat) {
    if(name === '') {
      setError({
        ...error,
        name: 'O Nome não pode ser nulo'
      })
      return false;
    }
    if(email === '') {
      setError({
        ...error,
        email: 'O Email não pode ser nulo'
      })
      return false;
    }
    if(phone === '') {
      setError({
        ...error,
        phone: 'O Telefone não pode ser nulo'
      })
      return false;
    }
    if(password === '') {
      setError({
        ...error,
        password: 'O Senha não pode ser nulo'
      })
      return false;
    }

    if(repeat === '') {
      setError({
        ...error,
        repeat: 'A confirmação não pode ser nula'
      })
      return false;
    }

    if(repeat !== password) {
      setError({
        ...error,
        password: 'A confirmação da senha tem que ser a mesma que a senha'
      })
      return false;
    }

    return true
  }

  async function Register() {
      const {name, email, phone, password, repeat} = inputValues
      resetErrors()
      const valid = validateInput(name, email, phone, password, repeat)
      if(valid) {
        const response = await register(name, email, phone, password)
        if(response) {
          resetForm()
          navigate('/')
        }
      }
  }

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Cadastro</title>
     <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ maxWidth: '800px', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Cadastrar
        </h4>
        <div className="input-form">
          <label>Nome</label>
          <div className="bor8 how-pos4-parent">
            <input data-test="input-register-name" value={inputValues.name} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="name" placeholder="Seu Nome"/>
          </div>
          {error.name && <span className='err'>{error.name}</span>}
        </div>

        <div className="input-form">
          <label>E-mail</label>
          <div className="bor8 how-pos4-parent">
            <input data-test="input-register-email" value={inputValues.email} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="text" name="email" placeholder="Seu E-mail"/>
          </div>
          {error.email && <span className='err'>{error.email}</span>}
        </div>

        <div className="input-form">
          <label>Telefone</label>
          <div className="bor8 how-pos4-parent">
          <InputMask
            className="cl2 plh3 size-116 p-l-10 p-r-30"
            mask='(99) 99999-9999'
            value={inputValues.phone}
            name="phone"
            data-test="input-register-phone"
            maskChar=" "
            onChange={(e) => {onChangeHandler(e)}}>
          </InputMask>
          </div>
          {error.phone && <span className='err'>{error.phone}</span>}
        </div>

        <div className="input-form">
          <label>Senha</label>
          <div className="bor8 how-pos4-parent">
            <input data-test="input-register-password" value={inputValues.password} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="password" placeholder="Sua Senha"/>
          </div>
          {error.password && <span className='err'>{error.password}</span>}
        </div>

        <div className="input-form">
          <label>Repetir a Senha</label>
          <div className="bor8 how-pos4-parent">
            <input data-test="input-register-repeat-password" value={inputValues.repeat} onChange={onChangeHandler} className="cl2 plh3 size-116 p-l-10 p-r-30" type="password" name="repeat" placeholder="Repita sua Senha"/>
          </div>
          {error.repeat && <span className='err'>{error.repeat}</span>}
        </div>
        <a href={undefined} onClick={Register} className="formSubmit flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer">
          Enviar
        </a>
      </div>
    </>
  )
}

export default Register;