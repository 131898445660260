/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
import { useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Card, Form } from "react-bootstrap"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination'
import NoImage from "src/components/templete/NoImage"
import { ClientContext } from "src/context/ClientContext"
import { ProductContext } from "src/context/ProductContext"
import { BranchContext } from "src/context/BranchContext"
import { useNavigate } from "react-router-dom"
import { SiteContext } from "src/context/SiteContext"
import ModalConfirm from "src/components/templete/ModalConfirm"
/* eslint-disable jsx-a11y/anchor-is-valid */
function MyProducts () {
  const [productId, setProductId] = useState(0);
  const navigate = useNavigate()
  const [showModal, setModalShow] = useState(false)
  const { getClientByToken, client } = useContext(ClientContext)
  const { notifyMessageWarning } = useContext(SiteContext)
  const { getAllBranchNoPaginate, branchs } = useContext(BranchContext)
  const {
    getAllProductsByClient,
    myProductsSettings,
    paginateMyProducts,
    getAllProductsByClientAndBranch,
    myProducts,
    getAllMyProductsByBranch,
    softDelete
  } = useContext(ProductContext)

  const [inputValues, setInputValues] = useState({
    search: "",
    branch_id: ""
  });

  const onChangeHandler = event => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };


  useEffect(()=> {
    getClientByToken()
    getAllBranchNoPaginate()
  }, [])

  useEffect(() => {
    if(branchs.length > 0) {
      if(!inputValues.branch_id) {
        inputValues.branch_id = branchs[0].id
      }
    }
  }, [branchs])

  useEffect(() => {
    if(client) {
      getAllProductsByClient('', client.id);
    }
  }, [client])

  const paginate = () =>{
    if(myProductsSettings && myProductsSettings.last_page > 1) {
      const {current_page, last_page, path} = myProductsSettings
      let active = current_page;
      let items = [];
      for (let number = 1; number <= last_page; number++) {
        items.push(
          <Pagination.Item onClick={() => { paginateMyProducts(path+'?page='+number)}} activeLabel={''} key={number} active={number === active}>
            {number}
          </Pagination.Item>,
        );
      }

      const paginationBasic = (
        <div>
          <Pagination>{items}</Pagination>
        </div>
      );

      return paginationBasic
    }
  }

  const modalDelete = (id) => {
    setProductId(id)
    setModalShow(true);
  }

  const deleteProduct = async () => {
    setModalShow(false)
    await softDelete(productId)
    getAllProductsByClient()
  }

  const listProducts = () => {
    return (
      myProducts.map((product)=> {
        return(
          <tr>
            <td>
              {product.images && product.images.image !== '' ?
              <img src={product.images.image} width={"100px"} />
              :
              <NoImage />
              }
            </td>
            <td>{product.name}</td>
            <td>{product.brand}</td>
            <td>{product.quantity}</td>
            <td>R$ {product.value}</td>
            <td><a onClick={() => { editProduct(product.id)}}><Button variant="primary">Visualizar</Button></a></td>
            <td><a onClick={() => { modalDelete(product.id)}} href={undefined}><Button variant="danger">Excluir</Button></a></td>
          </tr>

        )
      })
    )
  }

  const search = () => {
    const { branch_id, search } = inputValues
    if(search) {
      getAllProductsByClientAndBranch(branch_id, search)
    } else {
      getAllMyProductsByBranch(branch_id)
    }
  }

  useEffect(() => {
    if(myProducts) {
      listProducts()
    }
  },[myProducts])

  const createProduct = () => {
    if(inputValues.branch_id !== "") {
      navigate(`/branch/${inputValues.branch_id}/product`)
    } else {
      notifyMessageWarning('Você precisa cadastrar uma loja, depois pode cadastrar um produto para essa loja')
    }
  }

  const editProduct = (id) => {
    navigate(`/product/${id}`)
  }

  const cardsProducts = () => {
    return (
      myProducts.map((product)=> {
        const image = product.images && product.images.image !== '' ? product.image : `${process.env.REACT_APP_APP_URL}assets/images/no-image.png`;
        return(
            <Card key={product.id} style={{ width: '100%', marginBottom: 10 }}>
              <Card.Img variant="top" src={image}/>
              <Card.Body>
                <Card.Title><h2>{product.name}</h2></Card.Title>
                <Card.Text style={{ padding: 10 }}>
                  <p style={{ padding: 5, fontSize: '17px' }}> Marca : {product.brand}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Quantidade : {product.quantity}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Preço : R$ {product.value}</p>
                </Card.Text>
                <a href={`my-store/${product.id}`}><Button variant="primary">Visualizar</Button></a>
                <a onClick={() => {modalDelete(product.id)}} href={undefined}><Button variant="danger">Excluir</Button></a>
              </Card.Body>
            </Card>
        )
      })
    )
  }

  const render = () => {
    if (window.innerWidth < 720) {
      return (cardsProducts())
    } else {
      return (
        <Table striped>
          <thead>
            <tr>
              <th width="15%">Logo</th>
              <th>Nome</th>
              <th>Cidade</th>
              <th>Bairro</th>
              <th>Produtos</th>
              <th>Visualizar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {listProducts()}
          </tbody>
        </Table>
      )
    }
  }

  return (
    <>
    <title>{process.env.REACT_APP_NAME} - Meus Produtos</title>
     <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md" style={{ width: '90%', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        <h4 className="mtext-105 cl2 txt-center p-b-30">
          Meus Produtos
        </h4>
        <Row>
          <Col>
            <div  style={{ float:'right', margin: '20px'}}>
              <a data-test="btn-new" onClick={createProduct} href={undefined}><Button variant="primary">Cadastrar Novo</Button></a>
            </div>
          </Col>
        </Row>

        <div>
          <Row className="align-items-center">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Loja</Form.Label>
                <Form.Select defaultValue={inputValues.branch_id} onChange={onChangeHandler} name="branch_id" className="form-control">
                  {branchs.map((branch) =>(
                    <option key={branch.id} selected={inputValues.branch_id=== branch.id} value={branch.id} >{branch.fantasy_name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control onChange={onChangeHandler} type="text" value={inputValues.search} name="search" placeholder="Nome" />
              </Form.Group>
            </Col>
            <Col xs="auto">
                <Button onClick={search} style={{ marginTop: '20px'}} className="mb-2" variant="primary">Pesquisar</Button>
            </Col>
          </Row>
        </div>

        {render()}
        <Row>
          <Col>
            <div  style={{ float:'right', margin: '20px'}}>
              {paginate()}
            </div>
          </Col>
        </Row>
      </div>
      <ModalConfirm title={'Excluir Produto'} description={'Tem certeza que deseja excluir essa produto ?'} closeModal={setModalShow} active={showModal} confirm={deleteProduct}/>
    </>
  )
}

export default MyProducts