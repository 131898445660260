/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import SearchModal from "./SearchModal"
import ProfileMenu from "./ProfileMenu"
import { useContext } from "react"
import { useCookies } from "react-cookie"
import { Alert } from "react-bootstrap"
import Footer from "src/components/templete/Footer"
import Loader from "src/components/templete/Loader"
import { Outlet, useLocation } from "react-router-dom"
import { SiteContext } from "src/context/SiteContext"
import { ProductContext } from "src/context/ProductContext"
import ModalProduct from "src/components/templete/ModalProduct"
import OfferNotification from "src/components/notification/OfferNotification"
import { OfferNotificationContext } from "src/context/OfferNotificationContext"

function Main() {
  const [cookies] = useCookies(["trocaPetToken", "trocaPetUser"])
  const { loading } = useContext(SiteContext)
  const { getProduct } = useContext(ProductContext)
  const { getAllOfferNotifications, offerNotifications } = useContext(OfferNotificationContext)
  const [activeMenu, setActiveMenu] = useState('/')
  const [showNotificationOffer, setShowNotificationOffer] = useState(false)
  const [showModalProduct, setShowModalProduct] = useState(false)
  const [showModalSearch, setShowModalSearch] = useState(false)
  const [showProfile, setShowProfile] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  let user = cookies.trocaPetUser && cookies.trocaPetUser !== 'undefined'  ? cookies.trocaPetUser : null

  const location = useLocation();
  useEffect(() => {
    if(user) {
      getAllOfferNotifications()
    }
    setActiveMenu(location.pathname)
  }, [user])

  function openNotificationOffer(e) {
    setShowProfile(false)
    setShowMobileMenu(false)
    setShowNotificationOffer(showNotificationOffer => !showNotificationOffer);
  }

  async function openModalProduct(productId) {
    setShowProfile(false)
    setShowMobileMenu(false)
    await getProduct(productId)
    setShowModalProduct(showModalProduct => !showModalProduct);
  }

  async function closeModalProduct() {
    setShowProfile(false)
    setShowMobileMenu(false)
    setShowModalProduct(false);
  }

  function openMobileMenu(e) {
    setShowProfile(false)
    setShowMobileMenu(showMobileMenu => !showMobileMenu);
  }

  function openModalSearch(e) {
    setShowProfile(false)
    setShowMobileMenu(false)
    setShowModalSearch(showModalSearch => !showModalSearch);
  }

  function openProfile(e) {
    setShowMobileMenu(false)
    setShowNotificationOffer(false)
    setShowProfile(showProfile => !showProfile);
  }


  return (
    <>
    <Loader active={loading} />
    {/* <!-- >>>>>>>>>>>>>>>>>>> Header part start >>>>>>>>>>>>>>>>>>>--> */}
    <header>
      { process.env.REACT_APP_ENV === 'production' ?
      <>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9023502471197777"
        crossorigin="anonymous"></script>
      </>
      :<></>}
      {/* <!-- Header desktop --> */}
      <div className="container-menu-desktop">
        {/* <!-- Topbar --> */}
        <div className="wrap-menu-desktop">
          <nav className="limiter-menu-desktop container">

            {/* <!-- Logo desktop --> */}
            <a href="/" className="logo">
              <img src={`${process.env.REACT_APP_APP_URL}assets/images/logo/logo.png`} alt="IMG-LOGO"/>
            </a>

            {/* <!-- Menu desktop --> */}
            <div className="menu-desktop ">
              <ul className="main-menu">
                <li className={activeMenu === '/' ? "active-menu" : ""}>
                  <a href="/">Home</a>
                </li>

                <li className={activeMenu === '/products' ? "active-menu" : ""}>
                  <a href="/products">Produtos</a>
                </li>

                <li className={activeMenu === '/stores' ? "active-menu" : ""}>
                  <a href="/stores">Lojas</a>
                </li>

                <li className={activeMenu === '/about' ? "active-menu" : ""}>
                  <a href="/about">Sobre nós</a>
                </li>

                <li className={activeMenu === '/contact' ? "active-menu" : ""}>
                  <a href="/contact">Contato</a>
                </li>
              </ul>
            </div>

            {/* <!-- Icon header --> */}
            <div className="wrap-icon-header flex-w flex-r-m">
              {user ?
              <div onClick={openNotificationOffer} className={offerNotifications.length > 0 ? "icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti" : "icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11"}
                 data-notify={offerNotifications.length}>
                <i className="zmdi zmdi-notifications"></i>
                {showNotificationOffer ?
                  <div className="notifications" >
                    <OfferNotification notifications={offerNotifications} />
                  </div>
                :
                  <></>
                }
              </div>
              : <></> }

              <a data-test="btn-header-login" onClick={openProfile} href={undefined}
                className="dis-block icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11">
                <i className={user !== null ? "zmdi zmdi-account-circle" :"zmdi zmdi-account"}></i>
              </a>
              <ProfileMenu setShowProfile={setShowProfile} status={showProfile}/>
            </div>
          </nav>
          {process.env.REACT_APP_ENV === 'homolog' ?
          <div>
            <Alert style={{ textAlign: 'center'}} key={'primary'} variant={'primary'}>
              Voce está em um ambiente de homologação
            </Alert>
          </div>
          :
          <></>
          }
        </div>
        <div style={{ height: '83px'}}></div>
      </div>

      {/* <!-- Header Mobile --> */}
      <div className="wrap-header-mobile">
        {/* <!-- Logo moblie --> */}
        <div className="logo-mobile">
          <a href="/">
            <img src={`${process.env.REACT_APP_APP_URL}assets/images/logo/logo.png`} alt="IMG-LOGO"/>
          </a>
        </div>

        {/* <!-- Icon header --> */}
        <div className="wrap-icon-header flex-w flex-r-m m-r-15">

        {user ?
            <div onClick={openNotificationOffer} className={offerNotifications.length > 0 ? "icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti" : "icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11"}
                 data-notify={offerNotifications.length}>
              <i className="zmdi zmdi-notifications"></i>
              {showNotificationOffer ?
                <div className="notificationsMobile">
                  <OfferNotification notifications={offerNotifications} />
                </div>
                :
                <></>
                }
            </div>
          : <></>
          }

          <a data-test="btn-header-login" href={undefined} onClick={openProfile} className="dis-block icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10">
            <i className={user !== null ? "zmdi zmdi-account-circle" :"zmdi zmdi-account"}></i>
          </a>
          <ProfileMenu setShowProfile={setShowProfile} status={showProfile} />
        </div>

        {/* <!-- Button show menu --> */}
        <div onClick={openMobileMenu} className="btn-show-menu-mobile hamburger hamburger--squeeze">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </div>
      </div>
      {process.env.REACT_APP_ENV === 'homolog' ?
      <div>
        <Alert style={{ textAlign: 'center'}} key={'primary'} variant={'primary'}>
          Voce está em um ambiente de homologação
        </Alert>
      </div>
      :
      <></>
      }
      {/* <!-- Menu Mobile --> */}
      <div className="menu-mobile" style={{ display: showMobileMenu ? "block" : ""}} >

        <ul className="main-menu-m">
          <li>
            <a href="/">Home</a>
          </li>

          <li>
            <a href="/products">Produtos</a>
          </li>

          <li>
            <a href="/stores">Lojas</a>
          </li>

          <li>
            <a href="/about">Sobre nós</a>
          </li>

          <li>
            <a href="/contact">Contato</a>
          </li>
        </ul>
      </div>
      <SearchModal showModalSearch={openModalSearch} status={showModalSearch}/>
      {/* <!-- Modal Search --> */}
    </header>
    {/* <!-- >>>>>>>>>>>>>>>>>>> Header part end >>>>>>>>>>>>>>>>>>>--> */}
    {/* <!-- >>>>>>>>>>>>>>>>>>> Cart part start >>>>>>>>>>>>>>>>>>>--> */}
    {/* <!-- >>>>>>>>>>>>>>>>>>> Cart part end >>>>>>>>>>>>>>>>>>>--> */}
    <Outlet context={{openModalProduct}} />
    <Footer />
    {/* <!-- >>>>>>>>>>>>>>>>>>> Back to top part start >>>>>>>>>>>>>>>>>>>--> */}
    <div className="btn-back-to-top" id="myBtn">
      <span className="symbol-btn-back-to-top">
        <i className="zmdi zmdi-chevron-up"></i>
      </span>
    </div>
    {/* <!-- >>>>>>>>>>>>>>>>>>> Back to top part end >>>>>>>>>>>>>>>>>>>--> */}
    {/* <!-- >>>>>>>>>>>>>>>>>>> Modal1 part start >>>>>>>>>>>>>>>>>>>--> */}
    <ModalProduct closeModalProduct={closeModalProduct} showModal={openModalProduct} status={showModalProduct}/>
	  {/* <!-- >>>>>>>>>>>>>>>>>>> Modal1 part end >>>>>>>>>>>>>>>>>>>--> */}
  </>
  );
}

export default Main;
