/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import Banners from "src/components/templete/Banners"
import { ProductContext } from "src/context/ProductContext"
import { CategoryContext } from "src/context/CategorieContext"
import ProductList from "src/components/templete/ProductList"
import SimpleSlider from "src/components/templete/SimpleSlider"
import ProductFilter from "src/components/templete/ProductFilter"
import PlaceHolderProducts from "src/components/placeholders/PlaceHolderProducts"
import { CityContext } from "src/context/CityContext"

function Index()  {
  const { openModalProduct } = useOutletContext()
  const { cities, getAllCity } = useContext(CityContext)
  const { loading, products, getAllProducts } = useContext(ProductContext)
  const { categories, getAllCategory } = useContext(CategoryContext)
  useEffect(() => {
    getAllProducts()
    getAllCategory()
    getAllCity()
  }, [])
    return (
      <>
        <title>Troca Pet</title>
        {/* <!-- >>>>>>>>>>>>>>>>>>> Slider part start >>>>>>>>>>>>>>>>>>>--> */}
        <SimpleSlider />
        {/* <!-- >>>>>>>>>>>>>>>>>>> Slider part end >>>>>>>>>>>>>>>>>>>--> */}
        <Banners />
        <section className="bg0 p-t-23 p-b-140">
          <div className="list-products" style={{minHeight: '50vh'}}>
            <div className="container">
              <div className="p-b-10">
                <h3 data-test="product-list" className="ltext-103 cl5">
                  Produtos para troca
                </h3>
              </div>
              <ProductFilter categories={categories} cities={cities} />
              {loading ?
                <PlaceHolderProducts />
                :
                <ProductList
                  loading={loading}
                  products={products}
                  categories={categories}
                  openModalProduct={openModalProduct}
                />
              }
          </div>
        </div>
      </section>
      </>
    )
}
export default Index