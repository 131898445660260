/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import 'react-chat-elements/dist/main.css'
import { MessageBox } from "react-chat-elements"
import { SiteContext } from "src/context/SiteContext"
import NoImage from "src/components/templete/NoImage"
import { OfferContext } from "src/context/OfferContext"
import { useNavigate, useParams } from "react-router-dom"
import { ClientContext } from "src/context/ClientContext"
import ModalConfirm from "src/components/templete/ModalConfirm"
import { OfferMessageContext } from "src/context/OfferMessageContext"
import { Alert, Button, Card, Form, Row, Table } from "react-bootstrap"
import { OfferNotificationContext } from "src/context/OfferNotificationContext"

function ManagerOffer() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { client } = useContext(ClientContext)
  const { offerMessages, getAllByOfferId, sendMessage } = useContext(OfferMessageContext)
  const { setReadAllOfferNotifications } = useContext(OfferNotificationContext)
  const { loading, getOffer, accept, reject } = useContext(OfferContext)
  const { notifyMessageWarning } = useContext(SiteContext)
  const [showModal, setModalShow] = useState(false)
  const [message, setMessage] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    title: '',
    description: '',
    status: ''
  })
  const [currentOffer, setCurrentOffer] = useState({
    id: null,
    product: {
      images: {
        image: ''
      },
      description: '',
      validity: ''
    },
    client: {
      id: '',
      name: ''
    },
    client_id: 0,
    client_product: '',
    offer_owner_status: '',
    product_owner_status: '',
    product_offer: [],
    amount_product_offer: 0,
    amount_products: 0,
    messages: []
  })
  const ownerOffer = client && client.id === currentOffer.client_id
  const offerAccept = currentOffer.offer_owner_status === 'A' && currentOffer.product_owner_status === 'A'
  const offerEnded = currentOffer.offer_owner_status === 'R' || currentOffer.product_owner_status === 'R' || offerAccept
  const getIsMobile = () => window.innerWidth <= 768;

  function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [key,value] = el.split('=');
      cookie[key.trim()] = value;
    })
    return cookie[cookieName];
  }

  const adminLogin = getCookie('adminLogin')

  useEffect(() => {
    const getData = async() => {
      const offer = await getOffer(id)
      if(offer) {
        setCurrentOffer(offer)
        if(!adminLogin || adminLogin === '') {
          setReadAllOfferNotifications(id)
        }
      } else {
        navigate('/')
      }
    }
    getData()
  },[])

  useEffect(() => {
    setTimeout(() => {
      getAllByOfferId(id)
    }, 9000);
  }, []);


  useEffect(() => {
      const onResize = () => {
        setIsMobile(getIsMobile());
      }
      window.addEventListener("resize", onResize);
      return () => {
          window.removeEventListener("resize", onResize);
      }
  }, []);

  useEffect(() => {
    if(offerMessages) {
      setCurrentOffer({
        ...currentOffer,
        messages: offerMessages
      })
    }
  },[offerMessages])

  const onChangeHandler = event => {
    setMessage(event.target.value);
  };

  const sendMessageOffer = async () => {
    if(!message || message === '') {
      notifyMessageWarning('Digite sua mensagem para enviar.')
      return
    }
    const response = await sendMessage(currentOffer.id, message)
    if(response) {
      setMessage('')
    }
  }

  const modalActionOffer = (status) => {
    if(ownerOffer) {
      if(status === 'R')  {
        setModalInfo({
          title: status === 'A' ? 'Aceitar Oferta': 'Cancelar Oferta',
          description: `Tem ceteza que deseja ${status === 'A'? 'aceitar' : 'cancelar'} essa oferta`,
          status: status
        })
      } else {
        setModalInfo({
          title: 'Aceitar Oferta',
          description: `Tem ceteza que deseja aceitar essa oferta`,
          status: status
        })
      }
    } else {
      setModalInfo({
        title: status === 'A' ? 'Aceitar Oferta': 'Rejeitar Oferta',
        description: `Tem ceteza que deseja ${status === 'A'? 'aceitar' : 'rejeitar'} essa oferta`,
        status: status
      })
    }
    setModalShow(true);
  }

  const actionOffer = async () => {
    setModalShow(false)
    if(modalInfo.status === 'A') {
      await accept(id)
    } else {
      await reject(id)
    }
    navigate(0)
  }

  const editOffer = () => {
    navigate(`/edit/offer/${id}`)
  }

  const offerStatus = () => {
    let message = ''
    let color = 'primary'
    const hide = currentOffer.offer_owner_status === 'W' && currentOffer.product_owner_status === 'W'

    if(currentOffer.offer_owner_status === 'R') {
      if(ownerOffer) {
        message = 'Você cancelou está oferta'
      } else {
        message = 'A Ofertante cancelou está oferta'
      }
      color = 'danger'
    }

    if (currentOffer.product_owner_status === 'R') {
      if(ownerOffer) {
        message = 'O propietário do produto cancelou a oferta'
      } else {
        message = 'Você cancelou está oferta'
      }
      color = 'danger'
    }

    if (currentOffer.product_owner_status === 'A') {
      if(ownerOffer) {
        message = 'O propietário do produto aceitou a oferta, só está faltando seu aceite'
      } else {
        message = 'Aguardando o aceite do ofertante'
      }
      color = 'info'
    }

    if (currentOffer.product_owner_status === 'A' && currentOffer.offer_owner_status === 'R') {
      if(ownerOffer) {
        message = 'Você cancelou está oferta'
      } else {
        message = 'O ofertante cancelou a oferta'
      }
      color = 'danger'
    }

    if (currentOffer.offer_owner_status === 'A' && currentOffer.product_owner_status === 'A') {
      message = 'Oferta aceita pelas duas partes e finalizada'
      color = 'success'
    }

    return (
      <>
        { !hide ?
        <Alert variant={color}>
          {message}
        </Alert>
        :
        <></>
        }
      </>
    )
  }

  const offerActions = () => {
    if(currentOffer.offer_owner_status === 'R') {
     return offerProductOwnerRejectActions()
    }

    if (currentOffer.product_owner_status === 'R') {
      return offerProductOwnerRejectActions()
    }

    if (currentOffer.product_owner_status === 'A'  && currentOffer.offer_owner_status !== 'A') {
      return offerProductOwnerAcceptActions()
    }
    if (currentOffer.offer_owner_status === 'A' && currentOffer.product_owner_status === 'A') {
      return <></>
    }

    if (currentOffer.offer_owner_status === 'W' && currentOffer.product_owner_status === 'W') {
      return offerWatingActions()
    }
  }

  const offerProductOwnerRejectActions = () => {
    if(ownerOffer) {
      return (
        <>
          <Button onClick={remakeOffer} variant="info" style={{ marginRight: '10px'}}>Refazer Oferta</Button>
        </>
      )
    }
  }

  const offerProductOwnerAcceptActions = () => {
    if(ownerOffer) {
      return (
        <>
          <Button onClick={editOffer} variant="info" style={{ marginRight: '10px'}}>Editar</Button>
          <Button onClick={() => { modalActionOffer('A')}} variant="success" style={{ marginRight: '10px'}}>Aceitar</Button>
          <Button onClick={() => { modalActionOffer('R')}} variant="danger">Cancelar</Button>
        </>
      )
    } else {
      return (
        <>
          <Button onClick={() => { modalActionOffer('R')}} variant="danger">Cancelar</Button>
        </>
      )
    }
  }

  const offerWatingActions = () => {
    if(ownerOffer) {
      return (
        <>
          <Button onClick={editOffer} variant="info" style={{ marginRight: '10px'}}>Editar</Button>
          <Button onClick={() => { modalActionOffer('R')}} variant="danger">Cancelar</Button>
        </>
      )
    } else {
      return (
        <>
          <Button onClick={() => { modalActionOffer('A')}} variant="success" style={{ marginRight: '10px'}}>Aceitar</Button>
          <Button onClick={() => { modalActionOffer('R')}} variant="danger">Rejeitar</Button>
        </>
      )
    }
  }

  const showInfoTrade = () => {
    if(offerAccept) {
      if(ownerOffer) {
        return ownerProductOfferinfos()
      } else {
        return ownerOfferinfos()
      }
    }
  }

  const ownerOfferinfos = () => {
    return (
      <Card style={{ width: '100%', marginBottom:'10px' }}>
        <Card.Body>
          <Card.Title>Informações para realizar a troca</Card.Title>
          <Card.Text>
            <div>Nome : {currentOffer.client.name}</div>
            <div>Telefone : {currentOffer.client.phone}</div>
            <div>E-mail : {currentOffer.client.email}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  const ownerProductOfferinfos = () => {
    return (
      <Card style={{ width: '100%', marginBottom:'10px' }}>
        <Card.Body>
          <Card.Title>Informações para realizar a troca</Card.Title>
          <Card.Text>
            <div>Nome : {currentOffer.client_product.name}</div>
            <div>Telefone : {currentOffer.client_product.phone}</div>
            <div>E-mail : {currentOffer.client_product.email}</div>
            <div>Endereço da Loja : <a target='_blank' href={`https://www.google.com/maps/place/${currentOffer.product.branch.street},${currentOffer.product.branch.number},${currentOffer.product.branch.district},${currentOffer.product.branch.city},${currentOffer.product.branch.zip_code}`}>{currentOffer.product.branch.street}, {currentOffer.product.branch.number} -
              {currentOffer.product.branch.district}, {currentOffer.product.branch.city} - {currentOffer.product.branch.zip_code}
            </a>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  const remakeOffer = () => {
    navigate(`/recreate/offer/${id}`)
  }

  const listProducts = () => {
    return (
      <Table striped>
        <thead>
          <tr>
            <th width="15%">Logo</th>
            <th>Nome</th>
            <th>Marca</th>
            <th>Qtd</th>
            <th>Validade</th>
            <th>Valor Unidade</th>
          </tr>
        </thead>
        <tbody>
          {currentOffer.product_offer.map((product)=> {
            return(
              <tr key={product.id}>
                <td>
                {product.product.images && product.product.imagesimage !== '' ?
                  <img src={product.product.images.image}  width="100%"/>
                  :
                  <NoImage />
                }
                </td>
                <td>{product.product.name}</td>
                <td>{product.product.brand}</td>
                <td>{product.quantity}</td>
                <td>{product.product.validity}</td>
                <td>R$ {product.product.value}</td>
                <td></td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  const cardProducts = () => {
    return (
      currentOffer.product_offer.map((product)=> {
        const image = product.product.images && product.product.images.image !== '' ? product.product.image : `${process.env.REACT_APP_APP_URL}assets/images/no-image.png`;
        return(
            <Card key={product.id} style={{ width: '100%', marginBottom: 10 }}>
              <Card.Img variant="top" src={image}/>
              <Card.Body>
                <Card.Title><h2>{product.product.name}</h2></Card.Title>
                <Card.Text style={{ padding: 10 }}>
                  <p style={{ padding: 5, fontSize: '17px' }}> Marca : {product.product.brand}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Quantidade : {product.quantity}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Validade : {product.product.validity}</p>
                  <p style={{ padding: 5, fontSize: '17px' }}> Valor : R$ {product.product.value}</p>
                </Card.Text>
              </Card.Body>
            </Card>
        )
      })
    )
  }


  const products = () => {
    if (isMobile) {
      return cardProducts()
    } else {
      return listProducts()
    }
  }

  return (<>
   <title>{process.env.REACT_APP_NAME} - Oferta de troca</title>

      <div className="p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md managerOffer" style={{ width: '90%', minHeight: '50vh', margin:'auto', marginTop: '100px', marginBottom:'100px' }}>
        {
          process.env.REACT_APP_ENV === 'local' && client ?
          ownerOffer ? 'Criador' : 'Dono do Produto'
          :
          <></>
        }
        <Card style={{ width: '100%' }}>
          <Card.Body>
            <Card.Title>Dados da Oferta</Card.Title>
            <Card.Text>
              <div style={{ float: 'right', marginBottom: '20px'}}>
                {offerActions()}
              </div>
              <div style={{ clear:'both'}}></div>
              {offerStatus()}

              {showInfoTrade()}
              <Row>
                <div className={isMobile ? 'productMobile' : 'product'}>
                  <div className="image">
                    {currentOffer.product.images && currentOffer.product.images.image !== "" ?
                    <img src={currentOffer.product.images.image}  width="100%"/>
                    :
                    <NoImage />
                    }
                  </div>
                  <div className="description">
                    <div>
                      <div className="title">Nome</div>
                      <div>{currentOffer.product.name}</div>
                    </div>
                    <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
                    <div>
                      <div className="title">Descrição</div>
                      <div>{currentOffer.product.description}</div>
                    </div>
                    <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
                    <div>
                      <div className="title">Validade</div>
                      <div>{currentOffer.product.validity}</div>
                    </div>
                    <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
                    <div>
                      <div className="title">Valor</div>
                      <div>R$ {currentOffer.product.value}</div>
                    </div>
                    <div style={{ border:'solid 1px silver', margin:' 10px'}}></div>
                    <div>
                      <div className="title">Quantia : {currentOffer.quantity} </div>
                      <div className="amount">Total : R$ {currentOffer.amount_product_offer || '0,00'}</div>
                    </div>
                  </div>
                </div>
                <div className={isMobile ? 'productsMobile' : 'products'}>
                  <h3>Produtos</h3>
                  <div>
                    {products()}
                  </div>
                  <div className="amount">Valor total em produtos: R$ {currentOffer.amount_products || '0,00'}</div>
                </div>
              </Row>
              <ModalConfirm title={modalInfo.title} description={modalInfo.description} closeModal={setModalShow} active={showModal} confirm={actionOffer}/>
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ width: '100%' }}>
          <Card.Body>
            <Card.Title>Mensagens</Card.Title>
            <Card.Text>
            <div className='chat'>
              {
              currentOffer.messages.map((message) => (
                <MessageBox
                  className={isMobile ? 'chat-mobile' : ''}
                  key={message.id}
                  position={message.client_id === client.id ? 'right' : 'left'}
                  title={message.client_id === client.id ? 'Você' : message.client.name}
                  type='text'
                  text={message.message}
                  date={new Date(message.created_at)}
                />
              ))
              }
            </div>
            {!offerEnded ?
              <>
                <Form.Group className="mb-3">
                  <Form.Control disabled={loading} onChange={onChangeHandler} type="text" value={message === null ? '' : message} name="description" placeholder="Descreva sua proposta" as="textarea" rows={3} />
                </Form.Group>
                <div className="col text-center">
                  <Button disabled={loading} onClick={sendMessageOffer} variant="success">Enviar Mensagem</Button>
                </div>
              </>
            :
            <></>
            }
            </Card.Text>
          </Card.Body>
        </Card>
    </div>
  </>);
}

export default ManagerOffer;