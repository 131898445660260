/* eslint-disable jsx-a11y/alt-text */
function PetMaket() {
  return (
    <>
    <title>{process.env.REACT_APP_NAME} - O crescimento do mercado de pestiscos pet food</title>
    <section className="bg0 p-t-23 p-b-140">
      <div className="publicity" style={{minHeight: '50vh', marginTop: '50px'}}>
        <div className="container">
          <div className="p-b-10">
            <h1 className="ltext-103 cl5">
              O Crescimento do mercado de pestiscos pet food
            </h1>

            <div style={{ marginTop:'50px'}}>
              <p className="stext-113">Um dos principais respons&aacute;veis pelo crescimento e faturamento do mercado pet no Brasil &eacute; o pet food. Estima-se que a alimenta&ccedil;&atilde;o dos animais chegue a representar<strong>&nbsp;67%</strong> do faturamento total da ind&uacute;stria pet. N&uacute;meros e previs&otilde;es como estes explicam o porqu&ecirc; de investir neste promissor mercado.</p>
              <br/>
              <p className="stext-113">Outros n&uacute;meros que impressionam d&atilde;o conta de que o Brasil tem a quarta maior popula&ccedil;&atilde;o de animais de estima&ccedil;&atilde;o no mundo. Segundo o IBGE, s&atilde;o mais de <strong>50 milh&otilde;es de c&atilde;es e 22 milh&otilde;es</strong> de gatos de estima&ccedil;&atilde;o em lares por todo o pa&iacute;s.</p>
              <br/>
              <div style={{ padding: '60px'}}>
                <img className='img-fluid shadow-4' src={`${process.env.REACT_APP_APP_URL}/assets/images/publicity/grafico-pet.jpg`} />
              </div>
              <p className="stext-113">Mas o sucesso dos neg&oacute;cios pets ainda levantam outra quest&atilde;o: o comportamento das pessoas em rela&ccedil;&atilde;o aos seus bichinhos. J&aacute; tem muito tempo em que os pets deixaram de ser tratados como simples animais e se tornaram efetivamente membros da fam&iacute;lia. E esse movimento fez com que surgisse um <b>&ldquo;boom&rdquo;</b> de consumo em algumas &aacute;reas pets, dentre elas a alimenta&ccedil;&atilde;o.</p>
              <br/>
              <p className="stext-113">Os tutores, cada vez mais, t&ecirc;m agregado conceitos como alimenta&ccedil;&atilde;o saud&aacute;vel e sustentabilidade quando o assunto tamb&eacute;m &eacute; pet food. Transformando este cen&aacute;rio em um gigantesco universo de possibilidades.</p>
              <br/>
              <p className="stext-113">Pensando nisso, petiscos se tornam tamb&eacute;m uma boa oportunidade de investimento. Principalmente porque os tutores d&atilde;o muito valor a este produto, usado, na maioria das vezes, para demonstrar amor, afeto e gratid&atilde;o ao seu bichinho. Um mimo como este, quem n&atilde;o gostaria de receber, n&atilde;o &eacute; mesmo?</p>
              <p className="stext-113">&nbsp;</p>
              <p className="stext-113">&nbsp;</p>
              <h2><strong>Mas, o que n&atilde;o pode faltar num petisco pet food?</strong></h2>
              <br/>
              <p className="stext-113">Algumas caracter&iacute;sticas s&atilde;o fundamentais para o desenvolvimento de um bom produto, como aspectos nutricionais e funcionais, cores e formatos, sabor e palatabilidade. Vale lembrar que esse tipo de item pode ser produzido de diferentes formas, como extrusados ou assados, por exemplo.</p>
              <br/>
              <p className="stext-113">Mas independente disso, eles precisam ser seguros, ter dureza e estabilidade adequados ao pet, principalmente em fun&ccedil;&atilde;o da idade &ndash; filhote, adulto ou idoso &ndash; e esp&eacute;cie.</p>
              <br/>
              <p className="stext-113">Diversos ingredientes e tecnologias est&atilde;o dispon&iacute;veis para transformar esses produtos em itens cada vez mais saud&aacute;veis, saborosos e muito mais atrativos, tanto ao pet quanto ao tutor. Dentre alguns desses itens, podemos destacar, por exemplo, os emulsificantes, as fibras, os real&ccedil;adores de sabor, os corantes naturais e os aromas.</p>
              <br/>
              <p className="stext-113">Os emulsificantes s&atilde;o ingredientes que agregam muitas funcionalidades. Eles melhoram a maquinabilidade em laminados e extrus&atilde;o, facilitam a desmoldagem e reduzem as quebras e perdas no shelf life. J&aacute; as fibras insol&uacute;veis, como a celulose, tem um papel muito interessante para melhorar tanto os aspectos tecnol&oacute;gicos como maquinabilidade, mas tamb&eacute;m agregam croc&acirc;ncia, efeito ben&eacute;fico a sa&uacute;de intestinal e s&atilde;o indicados para a sa&uacute;de dental de pets e controle de bola de pelos em gatos.</p>
              <p className="stext-113"><br/></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default PetMaket;