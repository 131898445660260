/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext } from "react"
import { BranchContext } from "src/context/BranchContext"
import Store from "src/components/templete/Store"

function StoreList (props) {
  const { getMorBranchs, branchsSettings } = useContext(BranchContext)
  function listStore () {
    const list = []
    props.branchs.forEach((branch) =>  {
      list.push (
        <Store key={branch.id} branch={branch} showModal={props.openModalProduct} />
      )
    })
    return list
  }

  return (
    <>
      {/* <!-- >>>>>>>>>>>>>>>>>>> Product part start >>>>>>>>>>>>>>>>>>>--> */}
        <div className="row isotope-grid">
          {props.branchs && props.branchs.length > 0 ?
            <>
            {listStore()}
            {(branchsSettings && branchsSettings.links &&  branchsSettings.links.next ?
            <div className="flex-c-m flex-w w-full p-t-45">
              <a onClick={getMorBranchs} href={undefined} className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                Carregar mais
              </a>
            </div>
            :
            <>
            </>
            )}
            </>
            :
            <></>
          }
        </div>
      {/* <!-- >>>>>>>>>>>>>>>>>>> Product part end >>>>>>>>>>>>>>>>>>>--> */}
    </>
  )
}

export default StoreList