import {
  React,
  useState,
  createContext
} from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from 'src/services/api'
export const SiteContext = createContext()

const SiteProvider = (prop) => {
  const { children } = prop
  const [loading, setLoading] = useState(false)
  const [notifyMessage, setNotifyMessage] = useState({
    text: null,
    type: 'success',
    open: false
  })

  const closeNotifyMessage = () => {
    setNotifyMessage({
      ...notifyMessage,
      open: false
    })
  }

  const notifyMessageSave = (text) => {
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const notifyMessageWarning = (text) => {
    toast.warn(`${text}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const notifyMessageError = (text, err) => {
    toast.error(`${text} \n ${err}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const getAddressByZipCode = async (zipCode) => {
    setLoading(true)
    return await api.get(`https://viacep.com.br/ws/${zipCode}/json/`)
    .then((response) => {
      if(response.data.erro) {
        notifyMessageWarning('Endereço não encontrado')
      }
      setLoading(false)
      return response
    })
    .catch((err) => {
      setLoading(false)
      notifyMessageWarning('Endereço não encontrado')
      console.log(err)
      return null
    })
  }

  const sendContact = async (mail, message) => {
    setLoading(true)
      return await api.post(`/contact`, { mail, message})
      .then((response) => {
        setLoading(false)
        notifyMessageSave("E-mail enviado com sucesso.")
        return true
      })
      .catch((err) => {
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        if(err[0] !== 'E') {
          notifyMessageError(err[0], errosList)
        } else {
          notifyMessageError('Erro ao enviar e-mail, tente mais tarde', '')
        }
        return false
      })
  }

  const getTokenRecovery = async (token) => {
      setLoading(true)
      return await api.get(`/password/recovery/${token}`)
      .then((response) => {
        setLoading(false)
        return true
      })
      .catch((err) => {

        return false
      })
  }

  const passwordRecovery = async (email) => {
      setLoading(true)
      return await api.post(`/password/recovery`, {type: 'C', email: email})
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Enviamos um e-mail para recuperação de sua senha.")
        return true
      })
      .catch((err) => {
        if(err.status === 404) {
          notifyMessageError(err.data.message, '')
        }
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        if(err[0] !== 'E') {
          notifyMessageError(err[0], errosList)
        } else {
          notifyMessageError('Erro ao enviar e-mail, tente mais tarde', '')
        }
        return false
      })
  }

  const changePassword = async (password, token) => {
      setLoading(true)
      return await api.post(`/password/recovery/${token}`, {password})
      .then((response) => {
        setLoading(false)
        notifyMessageSave("Alteração de senha realizada com sucesso.")
        return true
      })
      .catch((err) => {
        if(err.status === 404) {
          notifyMessageError(err.data.message, '')
        }
        setLoading(false)
        const erros = Object.values(err[1])
        const errosList = erros.map((er) => {
          return `\n ${er}`
        })
        if(err[0] !== 'E') {
          notifyMessageError(err[0], errosList)
        } else {
          notifyMessageError('Erro ao enviar e-mail, tente mais tarde', '')
        }
        return false
      })
  }

  return (
    <SiteContext.Provider
      value={{
        loading,
        notifyMessage,
        setLoading,
        sendContact,
        getTokenRecovery,
        passwordRecovery,
        changePassword,
        getAddressByZipCode,
        setNotifyMessage,
        closeNotifyMessage,
        notifyMessageWarning,
        notifyMessageError,
        notifyMessageSave
      }}
    >
      { children }
      <ToastContainer />
    </SiteContext.Provider>

  )
}

export default SiteProvider
